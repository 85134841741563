import type { Store } from 'redux';

import type { Experiment } from 'common/experiments/Experiment';
import type { StoreState } from 'common/types/storeState';

import ForceFailsafe from './forceFailsafe';
import LinearWebVerticalFitPlayer from './linearWebVerticalFitPlayer';
import OTTAndroidtvIntroVideoFps from './ottAndroidtvIntroVideoFps';
import OTTAndroidtvOnetapReturningUsers from './ottAndroidtvOnetapReturningUsers';
import OTTAndroidTVRegistrationOnly from './ottAndroidTVRegistrationOnly';
import OTTAndroidTVRegistrationSecondaryMethod from './ottAndroidTVRegistrationSecondaryMethod';
import OTTComcastHlsUpgrade from './ottComcastHlsUpgrade';
import OTTComcastRegistrationEnableEnterEmail from './ottComcastRegistrationEnableEnterEmail';
import OTTComcastSkipAdWithHealthscore from './ottComcastSkipAdWithHealthscore';
import OTTFireTVAfttModelDedicatedAdPlayer from './ottFireTVAfttModelDedicatedAdPlayer';
import OTTFireTVAutocomplete from './ottFireTVAutocomplete';
import OTTFireTVBrowseWhileWatching from './ottFireTVBrowseWhileWatching';
import OTTFireTVCacheBandWidthEstimate from './ottFireTVCacheBandWidthEstimate';
import OTTFireTVContentNotFound from './ottFireTVContentNotFound';
import OTTFireTVDcPromotionRow from './ottFireTVDcPromotionRow';
import OTTFireTVDebounceBackgroundImageRerun from './ottFireTVDebounceBackgroundImageRerun';
import OTTFireTVDetachHlsCacheFragments from './ottFireTVDetachHlsCacheFragments';
import OTTFireTVDetachHlsDuringAdsPhase2 from './ottFireTVDetachHlsDuringAdsPhase2';
import OTTFireTVDirectlyToLinearPlayer from './ottFireTVDirectlyToLinearPlayer';
import OTTFireTVErrorModalRedesign from './ottFireTVErrorModalRedesign';
import OTTFireTVGate1080pResolution from './ottFireTVGate1080pResolution';
import OTTFiretvHlsUpgrade from './ottFiretvHlsUpgrade';
import OTTFireTVIncreaseMaxBufferLength from './ottFireTVIncreaseMaxBufferLength';
import OTTFireTVLandscapeCW from './ottFireTVLandscapeCW';
import OTTFireTVLargerPoster from './ottFireTVLargerPoster';
import OTTFireTVLevelFragFastFail from './ottFireTVLevelFragFastFail';
import OTTFireTVLinearErrorSlate from './ottFireTVLinearErrorSlate';
import OTTFireTVLinearFavoriteChannel from './ottFireTVLinearFavoriteChannel';
import OTTFireTVLiveErrorProcessor from './ottFireTVLiveErrorProcessor';
import OTTFireTVNativeCaptionsCache from './ottFireTVNativeCaptionsCache';
import OTTFireTVNewCategoryPage from './ottFireTVNewCategoryPage';
import OTTFireTVPlayerUIRefreshAdPlayer from './ottFireTVPlayerUIRefreshAdPlayer';
import OTTFireTVPosterLabelsNav from './ottFireTVPosterLabelsNav';
import OTTFireTVPreInitExtension from './ottFireTVPreInitExtension';
import OTTFireTVPreventFalseImpression from './ottFireTVPreventFalseImpression';
import OTTFireTVProgressiveFetch from './ottFireTVProgressiveFetch';
import OTTFireTVRegistrationEnableEnterEmail from './ottFireTVRegistrationEnableEnterEmail';
import OTTFireTVRemoveOnboarding from './ottFireTVRemoveOnboarding';
import OTTFireTVResponsivePlayerSize from './ottFireTVResponsivePlayerSize';
import OTTFireTVResumeWithLowestLevel from './ottFireTVResumeWithLowestLevel';
import OTTFireTVRTU from './ottFireTVRTU';
import OTTFireTVSecondPast from './ottFireTVSecondPast';
import OTTFireTVSeriesAutoplayShowMoreContents from './ottFireTVSeriesAutoplayShowMoreContents';
import OTTFireTVSkinsAd from './ottFireTVSkinsAd';
import OTTFireTVSkipAdWithHealthscore from './ottFireTVSkipAdWithHealthscore';
import OTTFireTVSpotlightCarouselNav from './ottFireTVSpotlightCarouselNav';
import OTTFireTVStartFromBeginningForAutoplayContent from './ottFireTVStartFromBeginningForAutoplayContent';
import OTTFireTVTitleOnboardingPersonalization from './ottFireTVTitleOnboardingPersonalization';
import OTTFireTVTitleTreatment from './ottFireTVTitleTreatment';
import OTTFireTVUseViewableImpressionOnProactiveRetryAds from './ottFireTVUseViewableImpressionOnProactiveRetryAds';
import OTTFireTVViewableImpressions from './ottFireTVViewableImpressions';
import OTTFireTVVODPlayerRefresh from './ottFireTVVODPlayerRefresh';
import OTTHisenseHlsUpgrade from './ottHisenseHlsUpgrade';
import OTTLGTVDedicatedAdPlayer from './ottLGTVDedicatedAdPlayer';
import OTTLGTVHlsUpgrade from './ottLGTVHlsUpgrade';
import OTTLgtvPauseAds from './ottLgtvPauseAds';
import OTTLGTVPreInitExtension from './ottLGTVPreInitExtension';
import OTTLGTVPrerollTimeManager from './ottLGTVPrerollTimeManager';
import OTTLGTVSecondPast from './ottLGTVSecondPast';
import OTTLGTVSkipAdWithHealthscore from './ottLGTVSkipAdWithHealthscore';
import OTTLgtvSrcNotSupportedErrorRecovery from './ottLgtvSrcNotSupportedErrorRecovery';
import OTTLGTVViewableImpressions from './ottLGTVViewableImpressions';
import OTTMultiplePlatformsDecreasePrerollBufferStall from './ottMultiplePlatformsDecreasePrerollBufferStall';
import OTTPlayerFireTVSimpleAdProgress from './ottPlayerFireTVSimpleAdProgress';
import OTTPlayerHisenseUseHlsAds from './ottPlayerHisenseUseHlsAds';
import OTTPlayerSamsungUseHls2015 from './ottPlayerSamsungUseHls2015';
import OTTPlayerSamsungUseHlsAds from './ottPlayerSamsungUseHlsAds';
import OTTPlayerSamsungUseHlsAdsNewer from './ottPlayerSamsungUseHlsAdsNewer';
import OTTPlayerSamsungUseSendBeacon from './ottPlayerSamsungUseSendBeacon';
import OTTPS4EnableTrailerOnWebmaf3 from './ottPS4EnableTrailerOnWebmaf3';
import OTTPS4HlsUpgrade from './ottPS4HlsUpgrade';
import OTTPS5HlsUpgrade from './ottPS5HlsUpgrade';
import OTTPS5PauseAds from './ottPS5PauseAds';
import OTTPs5SkipAdWithHealthscore from './ottPs5SkipAdWithHealthscore';
import OTTReactQueryCompatibility from './ottReactQueryCompatibility';
import OTTSamsungDedicatedAdPlayer from './ottSamsungDedicatedAdPlayer';
import OTTSamsungDeprecateHealthCheck from './ottSamsungDeprecateHealthCheck';
import OTTSamsungDisableOneMinRetryMx from './ottSamsungDisableOneMinRetryMx';
import OTTSamsungHlsUpgrade from './ottSamsungHlsUpgrade';
import OTTSamsungPauseAds from './ottSamsungPauseAds';
import OTTSamsungRegistrationGenderCollection from './ottSamsungRegistrationGenderCollection';
import OTTSamsungRepositionVideoResource from './ottSamsungRepositionVideoResource';
import OTTSamsungSingleScreenOnboarding from './ottSamsungSingleScreenOnboarding';
import OTTSamsungSynchronousVerification from './ottSamsungSynchronousVerification';
import OTTSamsungVibes from './ottSamsungVibes';
import OTTShowMetadataOnSearch from './ottShowMetadataOnSearch';
import OTTStagingRedirect from './ottStagingRedirect';
import OTTTivoHlsUpgrade from './ottTivoHlsUpgrade';
import OTTVizioDetachHlsDuringAdsPhase2 from './ottVizioDetachHlsDuringAdsPhase2';
import OTTVizioHandleAdStart from './ottVizioHandleAdStallAtStart';
import OTTVizioHlsUpgrade from './ottVizioHlsUpgrade';
import OTTVizioPauseAds from './ottVizioPauseAds';
import OTTVizioPreInitExtension from './ottVizioPreInitExtension';
import OTTVizioPromptLegalTextChange from './ottVizioPromptLegalTextChange';
import OTTVizioRegistrationSignInWithVizio from './ottVizioRegistrationSignInWithVizio';
import OTTVizioReturningUserPrompt from './ottVizioReturningUserPrompt';
import OTTVizioSkipAdWithHealthscore from './ottVizioSkipAdWithHealthscore';
import OTTXboxoneHlsUpgrade from './ottXboxoneHlsUpgrade';
import OTTXboxOnePauseAds from './ottXboxOnePauseAds';
import OTTXboxonePauseFragmentDownload from './ottXboxonePauseFragmentDownload';
import OTTXboxoneSkipAdWithHealthscore from './ottXboxoneSkipAdWithHealthscore';
import PlayerWebTheaterMode from './playerWebTheaterMode';
import WebAdAbnormalErrorConstrainView from './webAdAbnormalErrorConstrainView';
import WebAllCategories from './webAllCategories';
import WebAnalyticsAnonymousToken from './webAnalyticsAnonymousToken';
import WebAndroidDisablePlayback from './webAndroidDisablePlayback';
import WebCastingButton from './webCastingButton';
import WebCelebrityYmal from './webCelebrityYmal';
import WebCwRowForGuestUsers from './webCwRowForGuestUsers';
import WebFeaturedRow from './webFeaturedRow';
import WebHlsUpgrade from './webHlsUpgrade';
import WebIosPlayback from './webIosPlayback';
import WebLastVideoResourceRetry from './webLastVideoResourceRetry';
import WebLinearPlayerPip from './webLinearPlayerPip';
import WebPersonalizationPrompt from './webPersonalizationPrompt';
import WebQrReferralsPausescreen from './webQrReferralsPausescreen';
import WebRegistrationMagicLink from './webRegistrationMagicLink';
import WebRegistrationPlayerGate from './webRegistrationPlayerGate';
import WebRemoveLandingPage from './webRemoveLandingPage';
import WebRepositionVideoResource from './webRepositionVideoResource';
import WebSimplifyRegistrationOnLikeButton from './webSimplifyRegistrationOnLikeButton';
import WebSkipAdWithHealthscore from './webSkipAdWithHealthscore';
import WebVerticalYmal from './webVerticalYmal';
import WebVideoPreview from './webVideoPreview';
import WebVODPlayerPip from './webVODPlayerPip';

const getExperiments: (store?: Store<StoreState>) => Experiment[] = (store) => [
  LinearWebVerticalFitPlayer(store),
  OTTAndroidTVRegistrationOnly(store),
  OTTSamsungVibes(store),
  OTTComcastRegistrationEnableEnterEmail(store),
  OTTComcastSkipAdWithHealthscore(store),
  OTTLGTVDedicatedAdPlayer(store),
  OTTFireTVAfttModelDedicatedAdPlayer(store),
  OTTFireTVLargerPoster(store),
  OTTFireTVCacheBandWidthEstimate(store),
  OTTFireTVContentNotFound(store),
  OTTFireTVDetachHlsCacheFragments(store),
  OTTFireTVDirectlyToLinearPlayer(store),
  OTTFireTVErrorModalRedesign(store),
  OTTFireTVGate1080pResolution(store),
  OTTFireTVIncreaseMaxBufferLength(store),
  OTTFireTVRTU(store),
  OTTFireTVLevelFragFastFail(store),
  OTTFireTVLinearErrorSlate(store),
  OTTFireTVLinearFavoriteChannel(store),
  OTTFireTVNativeCaptionsCache(store),
  OTTFireTVPlayerUIRefreshAdPlayer(store),
  OTTFireTVPosterLabelsNav(store),
  OTTFireTVPreInitExtension(store),
  OTTFireTVPreventFalseImpression(store),
  OTTFireTVProgressiveFetch(store),
  OTTFireTVRegistrationEnableEnterEmail(store),
  OTTFireTVRemoveOnboarding(store),
  OTTFireTVResponsivePlayerSize(store),
  OTTFireTVResumeWithLowestLevel(store),
  OTTFireTVSeriesAutoplayShowMoreContents(store),
  OTTFireTVUseViewableImpressionOnProactiveRetryAds(store),
  ForceFailsafe(store),
  OTTHisenseHlsUpgrade(store),
  OTTFireTVSpotlightCarouselNav(store),
  OTTFireTVTitleOnboardingPersonalization(store),
  OTTFireTVTitleTreatment(store),
  OTTFireTVNewCategoryPage(store),
  OTTFireTVViewableImpressions(store),
  OTTFireTVVODPlayerRefresh(store),
  OTTFireTVAutocomplete(store),
  OTTFireTVBrowseWhileWatching(store),
  OTTFireTVDcPromotionRow(store),
  OTTFireTVDebounceBackgroundImageRerun(store),
  OTTFireTVDetachHlsDuringAdsPhase2(store),
  OTTFireTVSkinsAd(store),
  OTTFireTVSkipAdWithHealthscore(store),
  OTTMultiplePlatformsDecreasePrerollBufferStall(store),
  OTTPlayerFireTVSimpleAdProgress(store),
  OTTPlayerHisenseUseHlsAds(store),
  OTTPlayerSamsungUseHlsAds(store),
  OTTPs5SkipAdWithHealthscore(store),
  OTTPlayerSamsungUseHlsAdsNewer(store),
  OTTPlayerSamsungUseSendBeacon(store),
  OTTReactQueryCompatibility(store),
  OTTPlayerSamsungUseHls2015(store),
  OTTPS4EnableTrailerOnWebmaf3(store),
  OTTPS4HlsUpgrade(store),
  OTTPS5HlsUpgrade(store),
  OTTSamsungDedicatedAdPlayer(store),
  OTTSamsungDeprecateHealthCheck(store),
  OTTSamsungDisableOneMinRetryMx(store),
  OTTSamsungHlsUpgrade(store),
  OTTSamsungRegistrationGenderCollection(store),
  OTTSamsungRepositionVideoResource(store),
  OTTSamsungSingleScreenOnboarding(store),
  OTTSamsungSynchronousVerification(store),
  OTTShowMetadataOnSearch(store),
  OTTStagingRedirect(store),
  OTTTivoHlsUpgrade(store),
  OTTVizioDetachHlsDuringAdsPhase2(store),
  OTTVizioHandleAdStart(store),
  OTTVizioPreInitExtension(store),
  OTTVizioPromptLegalTextChange(store),
  OTTVizioRegistrationSignInWithVizio(store),
  OTTVizioReturningUserPrompt(store),
  OTTXboxoneSkipAdWithHealthscore(store),
  PlayerWebTheaterMode(store),
  WebAdAbnormalErrorConstrainView(store),
  WebAnalyticsAnonymousToken(store),
  WebAndroidDisablePlayback(store),
  WebCastingButton(store),
  OTTLGTVViewableImpressions(store),
  OTTLGTVSkipAdWithHealthscore(store),
  OTTLgtvSrcNotSupportedErrorRecovery(store),
  OTTFireTVLiveErrorProcessor(store),
  WebRegistrationMagicLink(store),
  WebRepositionVideoResource(store),
  WebSimplifyRegistrationOnLikeButton(store),
  WebSkipAdWithHealthscore(store),
  WebVerticalYmal(store),
  WebVideoPreview(store),
  WebCwRowForGuestUsers(store),
  WebVODPlayerPip(store),
  WebAllCategories(store),
  OTTAndroidtvIntroVideoFps(store),
  OTTAndroidtvOnetapReturningUsers(store),
  OTTAndroidTVRegistrationSecondaryMethod(store),
  /** Hls normalization upgrade **/
  OTTComcastHlsUpgrade(store),
  OTTFiretvHlsUpgrade(store),
  OTTFireTVLandscapeCW(store),
  OTTFireTVStartFromBeginningForAutoplayContent(store),
  OTTVizioHlsUpgrade(store),
  OTTLGTVHlsUpgrade(store),
  OTTLGTVPreInitExtension(store),
  OTTLGTVPrerollTimeManager(store),
  OTTFireTVSecondPast(store),
  OTTLGTVSecondPast(store),
  OTTVizioSkipAdWithHealthscore(store),
  OTTXboxoneHlsUpgrade(store),
  OTTXboxonePauseFragmentDownload(store),
  WebIosPlayback(store),
  WebFeaturedRow(store),
  WebHlsUpgrade(store),
  WebLastVideoResourceRetry(store),
  WebLinearPlayerPip(store),
  WebPersonalizationPrompt(store),
  WebCelebrityYmal(store),
  WebQrReferralsPausescreen(store),
  WebRegistrationPlayerGate(store),
  WebRemoveLandingPage(store),
  /* pause ads */
  OTTVizioPauseAds(store),
  OTTLgtvPauseAds(store),
  OTTSamsungPauseAds(store),
  OTTXboxOnePauseAds(store),
  OTTPS5PauseAds(store),
];

export default getExperiments;
