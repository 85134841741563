import type { Device } from '@tubitv/analytics/lib/client';

import systemApi from 'client/systemApi';
import { setupDsBridgeForDevEnv, isBridgeAvailable, getBridge } from 'client/utils/clientTools';
import { setAnalyticsConfig } from 'common/actions/tracking';
import { SET_OTT_DEVICE_INFO } from 'common/constants/action-types';
import logger from 'common/helpers/logging';
import tubiHistory from 'common/history';
import type { TubiThunkDispatch } from 'common/types/reduxThunk';
import { actionWrapper } from 'common/utils/action';
import { getDebugLog } from 'common/utils/debug';
import { getOTTRemote } from 'common/utils/keymap';

const debug = getDebugLog('ottApp');
const REMOTE = getOTTRemote();

export function onHybBackPress() {
  const backEvent = new KeyboardEvent('keyup', {
    bubbles: true,
    cancelable: true,
  });

  Object.defineProperty(backEvent, 'keyCode', { value: REMOTE.back });

  // dispatchEvent() return value is false if at least one of the event handlers which handled this event called Event.preventDefault(). Otherwise it returns true.
  const cancelled = !window.dispatchEvent(backEvent);

  if (!cancelled) {
    tubiHistory.goBack();
  }
}

export async function hybCDM(dispatch: TubiThunkDispatch, deviceInfoCallback?: (...args: any[]) => void) {
  let bridge;
  let deviceInfo;

  // @note if you want to test dsbridge for firetv locally, either hardcode isDsBridgeAvailable or set a cookie `o_cnav=2.13.10_151`
  if (isBridgeAvailable()) {
    if (__DEVELOPMENT__) {
      setupDsBridgeForDevEnv({
        getDeviceInfo: /* istanbul ignore next */ () => ({
          result: {
            device_type: 'FireTV',
            device_name: 'FireTV Dev Device',
            app_launch_ts: Date.now(),
            app_resume_ts: Date.now(),
            webview_latest_start_ts: Date.now(),
          },
        }),
      });
    }
    bridge = getBridge({ debug: !__PRODUCTION__ || __IS_ALPHA_ENV__ });

    try {
      bridge.registerHandler('onBackPress', onHybBackPress);
      bridge.init();
      deviceInfo = systemApi.getDeviceInfo() || await bridge.callHandler('getDeviceInfo') || {};
      if (__IS_ANDROIDTV_HYB_PLATFORM__) {
        const {
          model: deviceModel,
          manufacturer: deviceManufacturer,
        } = deviceInfo as Device;

        dispatch(actionWrapper(SET_OTT_DEVICE_INFO, {
          deviceModel,
          deviceManufacturer,
        }));
        // Update device model and manufacturer in analytics config
        dispatch(setAnalyticsConfig());
      }

      debug('getDeviceInfo', deviceInfo);
    } catch (err) {
      logger.error(err, 'dsbridge: error when call getDeviceInfo');
      return;
    }
  }

  if (typeof deviceInfoCallback === 'function') {
    deviceInfoCallback(deviceInfo);
  }
}
