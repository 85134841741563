import type { ReactNode } from 'react';
import React, { createContext, useMemo, useRef } from 'react';

import type { Experiment } from 'common/experiments/Experiment';

type ExperimentMap = { [key: string]: Experiment };

export const YouboraExperimentMapContext = createContext<{ getMap:() => ExperimentMap; addExperimentToMap:(map: Experiment) => void; } | undefined>(undefined);

export function YouboraExperimentMapProvider({ children }: { children?: ReactNode }) {
  const experimentMap = useRef<ExperimentMap>({});
  const provided = useMemo(() => ({
    getMap: () => experimentMap.current,
    addExperimentToMap: (experiment: Experiment) => {
      experimentMap.current[experiment.id] = experiment;
    },
  }), [experimentMap]);
  return <YouboraExperimentMapContext.Provider value={provided}>{children}</YouboraExperimentMapContext.Provider>;
}

