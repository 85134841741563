import type { Request } from 'express';
import React from 'react';
import type { ComponentClass } from 'react';
import type { RouteComponent, RouteConfig, RouteProps } from 'react-router';
import { IndexRoute, Redirect, Route } from 'react-router';
import type { Store } from 'redux';

import { OTT_ROUTES } from 'common/constants/routes';
import OttFireTVNewCategoryPage from 'common/experiments/config/ottFireTVNewCategoryPage';
import { ottDeeplinkOnEnterHook } from 'common/features/ottDeeplink/utils/ottDeeplinkOnEnterHook';
import App from 'ott/containers/App/App';

import {
  noLoginRequired,
  androidPlaybackOnEnterHook,
  moviesTVShowsOnEnterHook,
  liveOnEnterHook,
  espanolModeOnEnterHook,
  ottHomeOnEnterHook,
  deeplinkOnEnterHook,
  containerDetailOnLeaveHook,
  onboardingOnEnterHook,
  livePlayerOnEnterHook,
  ottAppEnterHook,
  redirectIfMajorEventFailsafeActive,
} from './helpers/routing';

// TODO: Suspense + React.lazy in React 18+?
function lazy(importPromiseFn: () => Promise<{ default: RouteComponent }>): RouteProps['getComponent'] {
  return (nextState, callback) => importPromiseFn().then(module => callback(null, module.default));
}

const LazyOTTHome = lazy(() => import(
  /* webpackChunkName: "ott-home" */
  'ott/containers/Home/Home'
));

// The typing of RouteProps does not include the exact property.
// The exact property should be required prior to react-router v6
const ExactRoute = Route as ComponentClass<RouteProps & {
  exact?: boolean,
  status?: string,
}>;

export default (store: Store, req: Request): RouteConfig => {
  const nativePlayerOnEnter = androidPlaybackOnEnterHook.bind(null, req);
  const moviesTVOnEnter = moviesTVShowsOnEnterHook.bind(null, store);
  const liveOnEnter = liveOnEnterHook.bind(null, store);
  const livePlayerOnEnter = livePlayerOnEnterHook.bind(null, store);
  const espanolModeOnEnter = espanolModeOnEnterHook.bind(null, store);
  const authPageOnEnter = noLoginRequired.bind(null, store);
  const ottHomeOnEnter = ottHomeOnEnterHook.bind(null, store);
  const deeplinkOnEnter = deeplinkOnEnterHook.bind(null, store);
  const ottDeeplinkOnEnter = ottDeeplinkOnEnterHook.bind(null, store);
  const containerDetailOnLeave = containerDetailOnLeaveHook.bind(null, store);
  const onboardingOnEnter = onboardingOnEnterHook.bind(null, store);
  const appOnEnter = ottAppEnterHook?.bind(null, store);
  const majorEventFailsafeOnEnter = redirectIfMajorEventFailsafeActive.bind(null, store);

  /* istanbul ignore next */
  const isNewCategoryPageEnabled: boolean = __CLIENT__ && OttFireTVNewCategoryPage(store).getValue();

  // NOTE in order to enable HMR in development env, we use `component` prop to explicitly load dynamic modules
  // @link https://github.com/gaearon/react-hot-loader/issues/288
  /* istanbul ignore next */
  return (
    <Route path={OTT_ROUTES.home} component={App} onEnter={appOnEnter}>
      { /* Home (main) route */ }
      <IndexRoute onEnter={ottHomeOnEnter} getComponent={LazyOTTHome} />
      <ExactRoute exact path={OTT_ROUTES.movieMode} onEnter={moviesTVOnEnter} getComponent={LazyOTTHome} />
      <ExactRoute exact path={OTT_ROUTES.tvMode} onEnter={moviesTVOnEnter} getComponent={LazyOTTHome} />
      <ExactRoute exact path={OTT_ROUTES.liveMode} onEnter={liveOnEnter} getComponent={LazyOTTHome} />
      {/* `/mode/news` is deprecated, redirect to `/mode/live` */}
      <Redirect from={OTT_ROUTES.deprecatedNewsMode} to={OTT_ROUTES.liveMode} />
      <ExactRoute exact path={OTT_ROUTES.espanolMode} onEnter={espanolModeOnEnter} getComponent={LazyOTTHome} />
      <ExactRoute exact path={OTT_ROUTES.myStuff} onEnter={majorEventFailsafeOnEnter} getComponent={LazyOTTHome} />
      <ExactRoute
        exact
        path={OTT_ROUTES.ageGate}
        getComponent={lazy(() => import(
          /* webpackChunkName: "ott-age-gate" */
          'ott/features/coppa/containers/AgeGatePage/AgeGatePage'
        ))}
      />
      <Route
        path={OTT_ROUTES.consentInitial}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-consent" */
            'ott/features/gdpr/containers/ConsentInitial/ConsentInitial'
          ))
        }
      />
      <Route
        path={OTT_ROUTES.privacyPreferences}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-consent" */
            'ott/features/gdpr/containers/PrivacyPreferences/PrivacyPreferences'
          ))
        }
      />
      {__OTTPLATFORM__ === 'TIZEN' || __DEVELOPMENT__ ? (
        <Route
          path={OTT_ROUTES.consentContinueWatching}
          getComponent={
            lazy(() => import(
              /* webpackChunkName: "ott-consent" */
              'ott/containers/ContinueWatchingConsent/ContinueWatchingConsent'
            ))
          }
        />) : null
      }

      <Route
        path={OTT_ROUTES.universalSignIn}
        onEnter={authPageOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-signin" */
            'ott/containers/SignIn/SignIn'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.amazonSSO}
        onEnter={authPageOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-signin" */
            'ott/features/authentication/containers/AmazonSSO/AmazonSSO'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.signInWithAmazon}
        onEnter={authPageOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-signin" */
            'ott/features/authentication/containers/SignInWithAmazon/SignInWithAmazon'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.signInWithGoogleOneTap}
        onEnter={authPageOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-signin" */
            'ott/features/authentication/containers/SignInWithGoogleOneTap/SignInWithGoogleOneTap'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.signInWithComcast}
        onEnter={authPageOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-signin" */
            'ott/features/authentication/containers/SignInWithComcast/SignInWithComcast'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.signInWithMagicLink}
        onEnter={authPageOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-signin" */
            'ott/features/authentication/containers/SignInWithMagicLink/SignInWithMagicLink'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.genderGate}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-signin" */
            'ott/features/authentication/containers/GenderGate/GenderGate'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.enterEmail}
        onEnter={authPageOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-enter-email" */
            'ott/containers/EnterEmail/EnterEmail'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.enterEmailWithEscape}
        onEnter={authPageOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-enter-email-with-escape" */
            'ott/containers/EnterEmailWithEscape/EnterEmailWithEscape'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.activate}
        onEnter={authPageOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-activate" */
            'ott/containers/OTTActivateWrapper/OTTActivateWrapper'
          ))
        }
      />

      {/* left side nav routes */}
      <Route
        path={OTT_ROUTES.containers} // Two types: regular (container) and channel
        getComponent={
          isNewCategoryPageEnabled
            ? lazy(
              () =>
                import(
                  /* webpackChunkName: "ott-containers-new" */
                  'ott/containers/ContainersNew/ContainersNew'
                )
            )
            : lazy(
              () =>
                import(
                  /* webpackChunkName: "ott-containers" */
                  'ott/containers/Containers/Containers'
                )
            )
        }
      />
      <Route
        path={OTT_ROUTES.containerDetail}
        onLeave={containerDetailOnLeave}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-container-detail" */
            'ott/containers/ContainerDetail/ContainerDetail'
          ))
        }
      />
      {/* content routes */}
      <Route
        path={OTT_ROUTES.video}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-details" */
            'ott/containers/OTTDetails/OTTDetails'
          ))
        }
      />
      <Route
        path={OTT_ROUTES.series}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-details" */
            'ott/containers/OTTDetails/OTTDetails'
          ))
        }
      />
      <Route
        path={OTT_ROUTES.episodeList}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-episodes" */
            'ott/containers/OTTEpisodes/OTTEpisodes'
          ))
        }
      />
      <Route
        path={OTT_ROUTES.androidPlayer}
        onEnter={nativePlayerOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-playback" */
            'ott/features/playback/containers/AndroidPlayback/AndroidPlayback'
          ))
        }
      />

      {/*
        There's a test in routing.spec.tsx that requires this route to be loaded immediately in dev
        so - leaving it for now.
      */}
      <Route
        path={OTT_ROUTES.player}
        component={__DEVELOPMENT__ ? require('ott/features/playback/containers/OTTContentPlayback/OTTContentPlayback').default : null}
        getComponent={
          __DEVELOPMENT__ ? undefined : lazy(() => import(
            /* webpackChunkName: "ott-playback" */
            'ott/features/playback/containers/OTTContentPlayback/OTTContentPlayback'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.trailer}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-trailer" */
            'ott/features/playback/containers/OTTTrailerPlayback/OTTTrailerPlayback'
          ))
        }
      />

      {/* live playback routes */}
      <Route
        path={OTT_ROUTES.livePlayer}
        onEnter={livePlayerOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-playback" */
            'ott/features/playback/containers/OTTLivePlayback/OTTLivePlayback'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.liveEntryPoint}
        onEnter={liveOnEnter}
        getComponent={
          lazy(() => import(
            'ott/containers/OTTEPG/OTTEPGToPlayer'
          ))
        }
      />

      {/* ad player route routes */}
      <Route
        path={OTT_ROUTES.adPlayer}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ad-playback" */
            'ott/features/playback/containers/OTTAdPlayback/OTTAdPlayback'
          ))
        }
      />

      {/* search routes */}
      <Route
        path={OTT_ROUTES.search}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-search" */
            'ott/containers/OTTSearch/OTTSearch'
          ))
        }
        onEnter={majorEventFailsafeOnEnter}
      />

      {/* "ott-parental-password" will replace "ott-parental"
       "ott-parental" can be removed after custom captions is on */}
      <Route
        path={OTT_ROUTES.parentalPassWord}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-parental-password" */
            'ott/containers/ParentalPassword/ParentalPassword'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.settings}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-settings" */
            'ott/containers/Settings/Settings'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.landing}
        onEnter={onboardingOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-landing" */
            'ott/containers/OTTLanding/OTTLanding'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.onboarding}
        onEnter={onboardingOnEnter}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-onboarding" */
            'ott/containers/OTTOnboarding/OTTOnboarding'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.personalizationTitle}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-personalization-title" */
            'ott/containers/OTTTitlePersonalization/OTTTitlePersonalization'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.personalizationTitleThankYou}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-personalization-title" */
            'ott/containers/OTTTitlePersonalization/OTTTitlePersonalization'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.chooseAccounts}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-choose-accounts" */
            'ott/containers/ChooseAccounts/ChooseAccounts'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.deeplink}
        onEnter={deeplinkOnEnter}
        component={() => null}
      />

      <Route
        path={OTT_ROUTES.ottDeeplink}
        onEnter={ottDeeplinkOnEnter}
        component={() => null}
      />

      <ExactRoute
        path={OTT_ROUTES.notFound}
        /* with v3 of react-router this property
           shouldn't be necessary. kept here during
           re-factor to enable the suppressExcessPropertyErrors
           work since this property was not defined in typescript
        */
        status="404"
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-notfound" */
            'ott/containers/OTTNotFound/OTTNotFound'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.ageUnavailable}
        component={__DEVELOPMENT__ ? require('ott/features/coppa/containers/AgeUnavailable/AgeUnavailable').default : null}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-ageUnavailable" */
            'ott/features/coppa/containers/AgeUnavailable/AgeUnavailable'
          ))
        }
      />

      <Route
        path={OTT_ROUTES.authError}
        getComponent={
          lazy(() => import(
            /* webpackChunkName: "ott-signin" */
            'ott/features/authentication/containers/AuthError/AuthError'
          ))
        }
      />

      {/* dev routes */}
      {
        __DEVELOPMENT__ || __STAGING__ || __IS_ALPHA_ENV__ ? (
          <Route
            path={OTT_ROUTES.dev}
            getComponent={
              lazy(() => import(
                /* webpackChunkName: "ott-dev" */
                'ott/containers/Dev/Dev'
              ))
            }
          />
        ) : null
      }
      {
        __DEVELOPMENT__ || __STAGING__ || __IS_ALPHA_ENV__ ? (
          <Route
            path={OTT_ROUTES.compatibility}
            getComponent={
              lazy(() => import(
                /* webpackChunkName: "ott-dev" */
                'ott/containers/Compatibility/Compatibility'
              ))
            }
          />
        ) : null
      }
      {
        __DEVELOPMENT__ || __STAGING__ || __IS_ALPHA_ENV__ ? (
          <Route
            path={OTT_ROUTES.playerDemo}
            getComponent={
              lazy(() => import(
                /* webpackChunkName: "ott-dev" */
                'ott/containers/Dev/PlayerDemo/PlayerDemo'
              ))
            }
          />
        ) : null
      }
      {
        __DEVELOPMENT__ || __STAGING__ || __IS_ALPHA_ENV__ ? (
          <Route
            path={OTT_ROUTES.featureSwitch}
            getComponent={
              lazy(() => import(
                /* webpackChunkName: "ott-dev" */
                'ott/containers/Dev/FeatureSwitch/FeatureSwitch'
              ))
            }
          />
        ) : null
      }
      {
        __DEVELOPMENT__ || __STAGING__ || __IS_ALPHA_ENV__ ? (
          <Route
            path={OTT_ROUTES.iconTest}
            getComponent={
              lazy(() => import(
                /* webpackChunkName: "ott-dev" */
                'ott/containers/Dev/IconTest/IconTest'
              ))
            }
          />
        ) : null
      }
    </Route>
  );
};
