import type { Location } from 'history';

import { getSystemApi } from 'client/systemApi/default';
import type SamsungSystemApi from 'client/systemApi/tizen';
import { removeLocalData } from 'client/utils/localDataStorage';
import * as actions from 'common/constants/action-types';
import {
  LD_DEFAULT_AUTOPLAY_VIDEO_PREVIEW,
  LD_DEFAULT_AUTOSTART_VIDEO_PREVIEW,
  LD_DEFAULT_PROMPT_AUTOSTART_VIDEO_PREVIEW,
  LD_DEFAULT_VIDEO_PREVIEW,
  IS_SUPPORT_REDIRECTING,
} from 'common/constants/constants';
import { LOG_SUB_TYPE, TRACK_LOGGING } from 'common/constants/error-types';
import { OTT_ROUTES, WEB_ROUTES } from 'common/constants/routes';
import { logout } from 'common/features/authentication/actions/auth';
import tubiHistory from 'common/history';
import type StoreState from 'common/types/storeState';
import { actionWrapper } from 'common/utils/action';
import { getAuthType } from 'common/utils/analytics';
import { trackLogging } from 'common/utils/track';
import { isLoginWithGoogleOneTapAvailableSelector } from 'ott/features/authentication/selectors/googleOneTap';
import { initBridgeFunctions } from 'ott/features/authentication/utils/googleOneTap/bridge';
import {
  getSSOPromptFirstSeenDate,
  isInSSOPromptEnabledPeriod,
  isSSOPromptEnabledPlatform,
  setSSOPromptShownLocalData,
} from 'ott/features/authentication/utils/prompt';

import type { TubiThunkAction } from '../types/reduxThunk';

export const handleSignOut = (location: Location): TubiThunkAction => {
  return (dispatch, getState: () => StoreState) => {
    const state = getState();
    const isByUser = true;

    if (!IS_SUPPORT_REDIRECTING) {
      // NOTE reload the whole page causes `webapis` missed, manually log out here
      dispatch(actionWrapper(actions.APP_RESET_START));
      return dispatch(logout(location, { isByUser }))
        .then(() => {
          tubiHistory.push(OTT_ROUTES.home);
          dispatch(actionWrapper(actions.APP_RESET_SUCCESS));
          // force update Samsung PMR to remove continue watching items for guest users
          if (__OTTPLATFORM__ === 'TIZEN') {
            (getSystemApi() as SamsungSystemApi).updatePreview();
          }
        })
        .catch((error: Error) => dispatch(actionWrapper(actions.APP_RESET_FAIL, { error })));
    }

    trackLogging({
      type: TRACK_LOGGING.clientInfo,
      subtype: LOG_SUB_TYPE.LOGOUT,
      message: {},
    });

    getSystemApi().onSignOut();

    if (isLoginWithGoogleOneTapAvailableSelector(state) && getAuthType(state) === 'GOOGLE') {
      initBridgeFunctions().signOutFromGoogleOneTap();
    }

    removeLocalData(LD_DEFAULT_VIDEO_PREVIEW);
    removeLocalData(LD_DEFAULT_AUTOSTART_VIDEO_PREVIEW);
    removeLocalData(LD_DEFAULT_AUTOPLAY_VIDEO_PREVIEW);
    removeLocalData(LD_DEFAULT_PROMPT_AUTOSTART_VIDEO_PREVIEW);

    if (isSSOPromptEnabledPlatform(__OTTPLATFORM__)) {
      // The isSSOPromptEnabledPlatform check above ensures that __OTTPLATFORM__ is definitely one of the SSOPromptEnabledPlatforms
      const firstSeenDate = getSSOPromptFirstSeenDate(__OTTPLATFORM__);
      if (isInSSOPromptEnabledPeriod(firstSeenDate)) {
        setSSOPromptShownLocalData(__OTTPLATFORM__);
      }
    }

    dispatch(logout(location, {
      isByUser,
      redirectPathAfterLogout: __ISOTT__ ? OTT_ROUTES.home : WEB_ROUTES.home,
    }));
  };
};
