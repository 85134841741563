import type { ThunkAction } from 'redux-thunk';
import type { ValueOf } from 'ts-essentials';

import { getSystemApi } from 'client/systemApi/default';
import { getCookie } from 'client/utils/localDataStorage';
import getConfig from 'common/apiConfig';
import * as actions from 'common/constants/action-types';
import { COOKIE_ADVERTISER_ID } from 'common/constants/cookies';
import { OTT_ROUTES, WEB_ROUTES } from 'common/constants/routes';
import { logoutUser, updateUser } from 'common/features/authentication/api/user';
import persistedQueryParamsSelector from 'common/features/authentication/selectors/persistedQueryParams';
import type { AuthThunk, User, UAPIAuthResponse } from 'common/features/authentication/types/auth';
import { redirectAfterLogout } from 'common/features/authentication/utils/persistedQueryParams';
import { isUserNotFound } from 'common/features/authentication/utils/user';
import { getUserToken } from 'common/features/authentication/utils/userToken';
import type { ApiClientMethodOptions, ApiClientMethods } from 'common/helpers/ApiClient';
import type ApiClient from 'common/helpers/ApiClient';
import logger from 'common/helpers/logging';
import { deviceIdSelector } from 'common/selectors/auth';
import type { TubiThunkAction, TubiThunkDispatch } from 'common/types/reduxThunk';
import type { StoreState } from 'common/types/storeState';
import { actionWrapper } from 'common/utils/action';
import { AUTH_ERROR_CODES, AUTH_ERROR_MESSAGES, pathToRoute } from 'common/utils/api';
import { getPlatform } from 'common/utils/platform';
import { isEndpointDisabled } from 'common/utils/remoteConfig';
import { getAnonymousTokenRequestOptions } from 'common/utils/token';
import { getIntl } from 'i18n/intl';

export interface FetchWithTokenOptions extends ApiClientMethodOptions {
  errorLog?: boolean;
  method?: ApiClientMethods;
  shouldAddAdvertiserId?: boolean;
  shouldUseRefreshToken?: boolean;
}

interface ApiError extends Error {
  code?: string | number;
  error?: string;
  httpCode?: number;
  status?: number;
}

const { uapi } = getConfig();

// endpoints that may only accept anonymous auth tokens
const ANONYMOUS_TOKEN_ONLY_ENDPOINTS: ValueOf<typeof uapi>[] = [
  uapi.emailAvailable,
  uapi.login,
  uapi.magicLink,
  uapi.userSignup,
];

const forceAnonymousToken = (url: string) => ANONYMOUS_TOKEN_ONLY_ENDPOINTS.includes(url);

export const logError = (url: string, fetchOptions: FetchWithTokenOptions, error: ApiError) => {
  const { errorLog = true, method, ...options } = fetchOptions;

  // don't log 404 error for web, except when running tests
  // don't log if errorLog option is false
  if ((error.httpCode === 404 && __WEBPLATFORM__ && !__TESTING__) || !errorLog) return;

  const path = new URL(url).pathname;

  const errorInfo = {
    error,
    errorMessage: error.message,
    statusCode: error.httpCode,
    options,
    path,
  };

  const route = pathToRoute(path);

  if (error.code === 'ESOCKETTIMEDOUT') {
    logger.error(errorInfo, `request timeout - ${route}`);
  } else if (
    error.httpCode === 401 ||
    error.httpCode === 403 ||
    (error.httpCode === 400 &&
      (AUTH_ERROR_CODES.includes(error.code as string) ||
        AUTH_ERROR_MESSAGES.includes(error.message) ||
        AUTH_ERROR_MESSAGES.includes(error.error as string)))
  ) {
    logger.info(errorInfo, `upstream authentication failure - ${route}`);
  } else if (error.httpCode === 404 || error.httpCode === 429) {
    logger.info(errorInfo, `Error [${method} request] - ${route}`);
  } else {
    logger.error(errorInfo, `Error [${method} request] - ${route}`);
  }
};

const decorateApiError = (error: ApiError, statusCode: number) => {
  error.code ??= statusCode;
  error.httpCode = statusCode;
  error.status ??= statusCode;

  return error;
};

const logoutAndRedirect = (): AuthThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const { user } = state.auth;
      await dispatch(logoutUser(user as User, { intentional: false }));
    } catch (err) {
      logger.info(err, 'logoutUser error in logoutAndRedirect');
    } finally {
      redirectAfterLogout({
        hasError: false,
        isByUser: false,
        path: __ISOTT__ ? OTT_ROUTES.home : WEB_ROUTES.home,
        persistedQueryParams: persistedQueryParamsSelector(state),
      });
    }
  };
};

interface HandleUserNotFoundParams {
  error: ApiError;
  opts: FetchWithTokenOptions;
  url: string;
}

export const handleUserNotFound = ({ error, opts, url }: HandleUserNotFoundParams): AuthThunk<Promise<void>> => {
  return (dispatch, getState) => {
    const {
      auth: { deviceId, user },
    } = getState();
    const { data, method } = opts;
    const logInfo = {
      clientUrl: typeof window !== 'undefined' && window.location?.pathname,
      deviceId,
      err: error,
      req: {
        body: data,
        method,
        url,
      },
      userInfo: user,
    };
    logger.info(logInfo, 'User not found, logging user out');

    // logout and reload the app
    return dispatch(logoutAndRedirect());
  };
};

const addAdvertiserIdToOptions = (options: ApiClientMethodOptions) => {
  options.params ??= {};
  const idfa = getCookie(COOKIE_ADVERTISER_ID) || getSystemApi().getAdvertiserId();
  if (idfa) {
    options.params.idfa = idfa;
  }
};

/**
 * retrieve a new access token for the logged in user, and attach to session
 * @note - the previous access token is still valid
 */
export const refreshToken = (): AuthThunk<Promise<User | void>> => {
  return (dispatch: TubiThunkDispatch, getState: () => StoreState) => {
    return dispatch(fetchTokenRefresh())
      .then((token?: User['token']) => {
        const { auth: { user } } = getState();
        if (token && user) {
          const updatedUser = {
            ...user as User,
            token,
          };
          dispatch(actionWrapper(actions.UPDATE_USER, { result: updatedUser }));
          return updatedUser;
        }
      });
  };
};

const sendRefreshTokenRequest = (): AuthThunk<Promise<UAPIAuthResponse | undefined>> => {
  return (dispatch, getState) => {
    const state = getState();
    const url = uapi.refresh;
    const options: FetchWithTokenOptions = {
      method: 'post',
      data: {
        platform: getPlatform(),
        device_id: deviceIdSelector(state),
      },
      shouldUseRefreshToken: true,
    };
    return dispatch(fetchWithToken<UAPIAuthResponse>(url, options));
  };
};

/**
 * Refresh the user access token
 * If this returns undefined, the app will continue to use the last known token
 */
export const fetchTokenRefresh = (): AuthThunk<Promise<User['token'] | undefined>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      auth: { user, userIP },
      ui: { userLanguageLocale },
    } = state;

    if (!user || !user.refreshToken) {
      return;
    }

    try {
      const response = await dispatch(sendRefreshTokenRequest());
      const accessToken = response?.access_token;

      if (!accessToken) {
        const {
          auth: { user },
        } = state;
        logger.error({ user, response }, 'unexpected response on refreshing token');
        return;
      }

      // update user in Redis on proxy server
      const intl = getIntl(userLanguageLocale);
      await dispatch(updateUser({ accessToken }, intl));

      return accessToken;
    } catch (err) {
      const logData = {
        deviceId: deviceIdSelector(state),
        err,
        errMessage: err.message,
        ip: userIP,
        status: err.status,
        user,
      };

      // According to https://docs.tubi.io/api_docs/account#operations-User-post_user_device_login_refresh
      // If server cannot verify the refresh token, it will return a 403.
      // Log the user out and reload the app.
      if (err.status === 403) {
        logger.info(logData, 'Server cannot verify the refresh token from the client');
        dispatch(logoutAndRedirect());
        return;
      }

      logger.error(logData, 'Error while refreshing token');
    }
  };
};
/**
 * An action to fetch an API request client-side that handles tokens, headers, and common ApiClient options
 */
export const fetchWithToken = <RESPONSE>(
  url: string,
  opts: FetchWithTokenOptions,
): TubiThunkAction<ThunkAction<Promise<RESPONSE>, StoreState, ApiClient, any>> => {
  return async (dispatch, getState, client) => {
    const { method = 'get', shouldAddAdvertiserId, shouldUseRefreshToken, ...options } = opts;
    const {
      auth: { user, userIP },
      ui: { userLanguageLocale },
    } = getState();

    if (isEndpointDisabled(url, method)) {
      return Promise.resolve();
    }

    options.headers ??= {};

    const isAuthorizationHeaderSet = !!options.headers.Authorization;
    if (!isAuthorizationHeaderSet) {
      if (user && !forceAnonymousToken(url)) {
        let authToken;
        if (shouldUseRefreshToken) {
          authToken = user.refreshToken;
        } else {
          authToken = await getUserToken(user as User, dispatch, refreshToken);
        }
        if (authToken) {
          options.headers.Authorization = `Bearer ${authToken}`;
        }
      } else {
        // for anonymous users, ApiClient handles setting the Authorization header with the anonymous token
        Object.assign(options, getAnonymousTokenRequestOptions());
        options.shouldSetAuthorizationHeader = true;
      }
    }

    if (shouldAddAdvertiserId) {
      addAdvertiserIdToOptions(options);
    }

    // add `Accept-Language` header
    options.headers['accept-language'] = userLanguageLocale;

    // these headers are only needed server-side to relay data to the backend API about the client
    if (__SERVER__) {
      // automatically append `x-forwarded-for` header to track client IP
      if (!options.headers['x-forwarded-for']) {
        options.headers['x-forwarded-for'] = userIP;
      }

      // log requests during SSR to help with debugging
      /* istanbul ignore else */
      if (process.env.TUBI_ENV !== 'testing') {
        logger.debug({ ...options }, `[${method} request] - ${url}`);
      }

      // add `x-client-platform` header for web
      if (!options.headers['x-client-platform'] && __WEBPLATFORM__ === 'WEB') {
        options.headers['x-client-platform'] = getPlatform();
      }
    }

    try {
      const response = await client[method](url, options);
      return response;
    } catch (err) {
      let error = err;
      const statusCode = err.status;

      error = decorateApiError(err, statusCode);

      /* istanbul ignore else */
      if (error) {
        logError(url, opts, error);
      }

      if (statusCode >= 500) {
        // use a custom error so we don't accidentally
        // return the original error to the client
        // and preserve the original error 'code' if it is defined
        error = new Error('Internal Error');
        error.code = err.code;
        error = decorateApiError(error, statusCode);
        return Promise.reject(error);
      }

      if (isUserNotFound(statusCode, error.code)) {
        await dispatch(handleUserNotFound({ error, opts, url }));
      }

      return Promise.reject(error);
    }
  };
};
