import { DialogType } from '@tubitv/analytics/lib/dialog';
import type { DialogAction } from '@tubitv/analytics/lib/dialog';
import type { Location } from 'history';

import { SHOW_MODAL, HIDE_MODAL } from 'common/constants/action-types';
import { DIALOG } from 'common/constants/event-types';
import { OTT_ROUTES } from 'common/constants/routes';
import type { AuthError } from 'common/features/authentication/types/auth';
import tubiHistory from 'common/history';
import type StoreState from 'common/types/storeState';
import { buildDialogEvent } from 'common/utils/analytics';
import { trackEvent } from 'common/utils/track';
import type { AlertModalProps } from 'ott/components/AlertModal/AlertModal';
import type { ErrorModalProps } from 'ott/components/ErrorModal/ErrorModal';
import type { ExitKidsModeModalProps } from 'ott/components/ExitKidsModeModal/ExitKidsModeModal';
import type { KidsModeEligibilityModalProps } from 'ott/components/KidsModeEligibilityModal/KidsModeEligibilityModal';
import type { NotFoundModalProps } from 'ott/components/NotFoundModal/NotFoundModal';
import type { OTTActivateErrorModalProps } from 'ott/components/OTTActivateErrorModal/OTTActivateErrorModal';
import type { OTTActivateStatusErrorModalProps } from 'ott/components/OTTActivateStatusErrorModal/OTTActivateStatusErrorModal';
import type { QueueOrHistoryEmptyModalProps } from 'ott/components/QueueOrHistoryEmptyModal/QueueOrHistoryEmptyModal';
import type { RestrictedContentModalProps } from 'ott/components/RestrictedContentModal/RestrictedContentModal';
import type { SamsungCWNotificationModalProps } from 'ott/components/SamsungCWNotificationModal/SamsungCWNotificationModal';
import type { TalkbackDisabledModalProps } from 'ott/components/TalkbackWarning/TalkbackDisabledModal';
import type { TalkbackWarningModalProps } from 'ott/components/TalkbackWarning/TalkbackWarningModal';
import type { AmazonSSOPromptProps } from 'ott/features/authentication/components/AmazonSSOPrompt/AmazonSSOPrompt';
import type { LoginPromptProps } from 'ott/features/authentication/components/LoginPrompt/LoginPrompt';
import type {
  MagicLinkErrorType,
  RequestMagicLinkErrorModalProps,
} from 'ott/features/authentication/components/RequestMagicLinkErrorModal/RequestMagicLinkErrorModal';
import type { RequireSignInModalProps } from 'ott/features/authentication/components/RequireSignInModal/RequireSignInModal';
import type { SignOutModalProps } from 'ott/features/authentication/components/SignOutModal/SignOutModal';
import type { WhyAgeModalProps } from 'ott/features/coppa/components/WhyAgeModal/WhyAgeModal';
import type { AudioDescriptionsModalProps } from 'ott/features/playback/components/AudioDescriptionsModal/AudioDescriptionsModal';
import type { DRMErrorModalProps } from 'ott/features/playback/components/DRMErrorModal/DRMErrorModal';
import type { FatalErrorModalProps } from 'ott/features/playback/components/FatalErrorModal/FatalErrorModal';
import type { FeedbackModalProps } from 'ott/features/playback/components/FeedbackModal/FeedbackModal';
import type { HDCPWarningModalProps } from 'ott/features/playback/components/HDCPWarningModal/HDCPWarningModal';
import type { SetupErrorModalProps } from 'ott/features/playback/components/SetupErrorModal/SetupErrorModal';
import type { SubtitlesModalProps } from 'ott/features/playback/components/SubtitlesModal/SubtitlesModal';
import type { RatingChangeModalProps } from 'ott/utils/parentalControls';

import type { NavigateCallback } from './fire';
import { dismissInAppMessageContent } from './fire';
import { handleSignOut } from './handleSignOut';
import type { TubiThunkAction, TubiThunkDispatch } from '../types/reduxThunk';

export type ModalType = 'alert' |
  'activateError' |
  'activateStatusError' |
  'audioDescriptions' |
  'error' |
  'drmError' |
  'fatalError' |
  'feedback' |
  'exitSignup' |
  'exitKidsMode' |
  'expired' |
  'hdcpWarning' |
  'inAppMessagePrompt' |
  'kidsModeEligibility' |
  'amazonSSOPrompt' |
  'loginPrompt' |
  'mature' |
  'notFound' |
  'queueOrHistoryEmpty' |
  'ratingChange' |
  'registrationPrompt' |
  'requireSignin' |
  'requestMagicLinkError' |
  'restrictedContent' |
  'samsungCWNotification' |
  'setupError' |
  'signOut' |
  'subtitles' |
  'talkbackDisabled' |
  'talkbackWarning' |
  'whyAge' |
  'exitTubi' |
  // a special modal type, renders nothing. This is for cases to show the backdrop only.
  'backdropOnly' |
  'signInWithVizio';

export interface ShowModalAction {
  type: typeof SHOW_MODAL;
  modal: { type: ModalType; props: unknown };
}

export function showModal(modalType: ModalType, modalProps: unknown = {}): ShowModalAction {
  return {
    type: SHOW_MODAL,
    modal: { type: modalType, props: modalProps },
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}

type ModalProps = {
  signIn(): void;
  closeModal(): void;
  videoTitle?: string;
  onMountCallback?: VoidFunction;
};

export function showAlertModal(modalProps: AlertModalProps) {
  return showModal('alert', modalProps);
}

export function showAudioDescriptionsModal(modalProps: AudioDescriptionsModalProps) {
  return showModal('audioDescriptions', modalProps);
}

export function showDRMErrorModal(modalProps: DRMErrorModalProps) {
  return showModal('drmError', modalProps);
}

export function showErrorModal(modalProps: ErrorModalProps) {
  return showModal('error', modalProps);
}

export function showExitKidsModeModal(modalProps: ExitKidsModeModalProps) {
  return showModal('exitKidsMode', modalProps);
}

export function showExitSignUpModal(modalProps: AlertModalProps) {
  return showModal('exitSignup', modalProps);
}

export function showFatalErrorModal(modalProps: FatalErrorModalProps) {
  return showModal('fatalError', modalProps);
}

export function showFeedbackModal(modalProps: FeedbackModalProps) {
  return showModal('feedback', modalProps);
}

export function showHDCPWarningModal(modalProps: HDCPWarningModalProps) {
  return showModal('hdcpWarning', modalProps);
}

export function showKidsModeEligibilityModal(modalProps: KidsModeEligibilityModalProps) {
  return showModal('kidsModeEligibility', modalProps);
}

export function showAmazonSSOPromptModal(modalProps: AmazonSSOPromptProps) {
  return showModal('amazonSSOPrompt', modalProps);
}

export function showLoginPromptModal(modalProps: LoginPromptProps) {
  return showModal('loginPrompt', modalProps);
}

export function showNotFoundModal(modalProps: NotFoundModalProps) {
  return showModal('notFound', modalProps);
}

export function showQueueOrHistoryModal(modalProps: QueueOrHistoryEmptyModalProps) {
  return showModal('queueOrHistoryEmpty', modalProps);
}

export function showRatingChangeModal(modalProps: RatingChangeModalProps) {
  return showModal('ratingChange', modalProps);
}

export function showRegistrationPromptModal(modalProps = {}) {
  return showModal('registrationPrompt', modalProps);
}

export function showRequireSignInModal(modalProps: RequireSignInModalProps) {
  return showModal('requireSignin', modalProps);
}

export function showSamsungCWNotificationModal(modalProps: SamsungCWNotificationModalProps) {
  return showModal('samsungCWNotification', modalProps);
}

export function showSetupErrorModal(modalProps: SetupErrorModalProps) {
  return showModal('setupError', modalProps);
}

export function showSubtitlesModal(modalProps: SubtitlesModalProps) {
  return showModal('subtitles', modalProps);
}

export function showTalkbackDisabledModal(modalProps: TalkbackDisabledModalProps) {
  return showModal('talkbackDisabled', modalProps);
}

export function showTalkbackWarningModal(modalProps: TalkbackWarningModalProps) {
  return showModal('talkbackWarning', modalProps);
}

export function showWhyAgeModal(modalProps: WhyAgeModalProps) {
  return showModal('whyAge', modalProps);
}

export function showExitTubiModal() {
  return showModal('exitTubi');
}

/**
 * Display a expired modal
 */
export function showExpiredModal(currentPathname: string): TubiThunkAction {
  return (dispatch, getState: () => StoreState) => {
    const { auth } = getState();
    const isLoggedIn = !!(auth && auth.user);
    const modalProps = {
      signIn() {
        tubiHistory.push(OTT_ROUTES.activate);
      },
      closeModal() {
        dispatch(hideModal());
      },
      isLoggedIn,
      dispatch,
    };
    trackEvent(DIALOG, buildDialogEvent(currentPathname, DialogType.INFORMATION));
    dispatch(showModal('expired', modalProps));
  };
}

type ShowModalOptions = {
  navigateFunction?: NavigateCallback;
  modalProps?: Record<string, unknown>;
};

/**
 * Display a modal prompt for the user to log in before viewing mature content.
 * @param currentPathname - The current navigation pathname
 */
export function showMatureContentModal(currentPathname: string, options: ShowModalOptions = {}): TubiThunkAction {
  const dialogEventPayload = { currentPathname };
  return showRegistrationModal(
    'mature',
    (dispatch: TubiThunkDispatch): ModalProps => ({
      signIn() {
        const url = OTT_ROUTES.activate;
        const navigateFunction = options.navigateFunction;
        if (typeof navigateFunction === 'function') {
          navigateFunction(url);
        } else {
          tubiHistory.push(url);
        }
      },
      closeModal() {
        dispatch(hideModal());
        // Hide modal and redirect user to player page if contentId exists
      },
      ...options.modalProps,
    }),
    dialogEventPayload
  );
}

/**
 * Display a parental control modal
 */
export function showRestrictedContentModal(
  currentPathname: string,
  noRedirectToHome: boolean = false
): TubiThunkAction {
  return (dispatch) => {
    const modalProps: RestrictedContentModalProps = {
      dispatch,
      noRedirectToHome,
    };
    // TODO(yuhao): change the dialog type from INFORMATION to CONTENT_RESTRICTED when the new type is supported by analytics service
    // https://app.clubhouse.io/tubi/story/137286/add-content-restricted-to-dialogtype-for-new-ott-dialog-event
    trackEvent(DIALOG, buildDialogEvent(currentPathname, DialogType.INFORMATION));
    dispatch(showModal('restrictedContent', modalProps));
  };
}

export const showSignOutModal = (location: Location): TubiThunkAction => {
  return (dispatch) => {
    const signOutModalProps: SignOutModalProps = {
      hideModal: () => {
        dispatch(hideModal());
      },
      signOut: () => {
        dispatch(handleSignOut(location));
      },
    };

    dispatch(showModal('signOut', signOutModalProps));
  };
};

type DialogEventPayload = {
  currentPathname: string;
  dialogType?: DialogType;
  dialogSubType?: string;
  dialogAction?: DialogAction;
};

/**
 * Display a modal prompt for the user to log in before viewing mature content.
 * @param Modal - the Modal Component to be displayed
 * @param modalPropsFn - a function to generate props for modal
 * @param dialogEventPayload - The payload required for dialogEvent
 */
export function showRegistrationModal(
  modalType: ModalType,
  modalPropsFn: (dispatch: TubiThunkDispatch) => ModalProps,
  dialogEventPayload: DialogEventPayload
): TubiThunkAction {
  return (dispatch) => {
    const modalProps = { ...modalPropsFn(dispatch), dispatch };
    const { currentPathname, dialogType, dialogSubType, dialogAction } = dialogEventPayload;
    trackEvent(
      DIALOG,
      buildDialogEvent(currentPathname, dialogType || DialogType.SIGNIN_REQUIRED, dialogSubType, dialogAction)
    );
    dispatch(showModal(modalType, modalProps));
  };
}

export function handleRequestMagicLinkError(errorType: MagicLinkErrorType): TubiThunkAction {
  return (dispatch) => {
    const modalProps: RequestMagicLinkErrorModalProps = { errorType };
    dispatch(showModal('requestMagicLinkError', modalProps));
  };
}

/**
 * Handle OTTActivate error and display an error modal
 */
export function showOTTActivationErrorModal(error: AuthError, onRetry: () => void): TubiThunkAction {
  return (dispatch) => {
    const modalProps: OTTActivateErrorModalProps = { status: error.status, onRetry };
    dispatch(showModal('activateError', modalProps));
  };
}

/**
 * Handle OTTActivate status check error and display an error modal
 */
export function showOTTActivationStatusErrorModal(onRetry: () => void): TubiThunkAction {
  return (dispatch) => {
    const modalProps: OTTActivateStatusErrorModalProps = { onRetry };
    dispatch(showModal('activateStatusError', modalProps));
  };
}

export function showInAppMessageModal() {
  return showModal('inAppMessagePrompt');
}

export const dismissInAppMessageContentPrompt = (): TubiThunkAction => {
  return (dispatch) => {
    dispatch(hideModal());
    dispatch(dismissInAppMessageContent());
  };
};

export const dim = () => {
  return showModal('backdropOnly');
};

