import { memo, useEffect } from 'react';

import { WEB_NON_PLAYER_ROUTES } from 'common/constants/routes';
import { useLocation } from 'common/context/ReactRouterModernContext';
import { getHlsChunk } from 'common/features/playback/utils/getHlsChunk';
import useAppSelector from 'common/hooks/useAppSelector';
import { playerHlsNormalizationUpgradeSelector } from 'common/selectors/experiments/playerHlsNormalizationUpgrade';
import { isPlaybackEnabledSelector } from 'common/selectors/ui';
import { safeRequestIdleCallback } from 'common/utils/async';

const PreloadBundle = () => {
  const { pathname } = useLocation();
  const isDisablePlayerBundles =
    __WEBPLATFORM__ === 'WEB' && WEB_NON_PLAYER_ROUTES.includes(pathname as (typeof WEB_NON_PLAYER_ROUTES)[number]);
  const preloadHlsJs = !isDisablePlayerBundles && (__SHOULD_USE_HLS__ || __IS_LIVE_NEWS_ENABLED__);
  const useHlsNext = useAppSelector(playerHlsNormalizationUpgradeSelector);
  const isPlaybackEnabled = useAppSelector(isPlaybackEnabledSelector);
  useEffect(() => {
    safeRequestIdleCallback(() => {
      if (preloadHlsJs) {
        getHlsChunk(useHlsNext);
      }
      if (isPlaybackEnabled && !__WEBPLATFORM__) {
        import(
          /* webpackChunkName: "youbora-monitoring", webpackPreload: true */ 'client/features/playback/monitor/monitoring'
        );
      }
    });
  }, [isPlaybackEnabled, preloadHlsJs, useHlsNext]);
  return null;
};

export default memo(PreloadBundle);

