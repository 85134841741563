export const FAKE_SALT_FOR_OVERRIDES = 'FORCED_OVERRIDE';

export enum PROGRESSIVE_MODE {
  CONTROL = 0,
  PROGRESSIVE_ANY_SPEED = 1,
  PROGRESSIVE_NORMAL_SPEED = 2,
  PROGRESSIVE_FAST_SPEED = 3
}

export const enum AdPlayerUIRefreshVariant {
  Default = 'default',
  V1 = 'single_ad_time_clean',
  V2 = 'all_ad_time_clean',
  V3 = 'all_ad_time',
  V4 = 'single_ad_time',
}
