import { fetchTokenRefresh } from 'common/actions/fetch';
import * as actions from 'common/constants/action-types';
import type { User, AuthThunk } from 'common/features/authentication/types/auth';
import type { TubiThunkDispatch } from 'common/types/reduxThunk';
import type { StoreState } from 'common/types/storeState';
import { actionWrapper } from 'common/utils/action';

/**
 * retrieve a new access token for the logged in user, and attach to session
 * @note - the previous access token is still valid
 */
export const refreshToken = (): AuthThunk<Promise<User | void>> => {
  return (dispatch: TubiThunkDispatch, getState: () => StoreState) => {
    return dispatch(fetchTokenRefresh())
      .then((token?: User['token']) => {
        const { auth: { user } } = getState();
        if (token && user) {
          const updatedUser = {
            ...user as User,
            token,
          };
          dispatch(actionWrapper(actions.UPDATE_USER, { result: updatedUser }));
          return updatedUser;
        }
      });
  };
};
