import type { MutableRefObject } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setTileIndexInContainer } from 'common/actions/setTileIndexInContainer';
import { ENABLE_OTT_MOUSE_EVENTS } from 'common/constants/constants';
import useAppSelector from 'common/hooks/useAppSelector';

export interface UseResetContainerRowIndexFromMouseSelectionOptions {
  lastSelectedIndexWhenInputModeWasDefaultMap: MutableRefObject<
    Record<string, number>
  >;
}

export type UseResetContainerRowIndexFromMouseSelectionHook = (params: UseResetContainerRowIndexFromMouseSelectionOptions) => (containerId: string) => void;

export const useResetContainerRowIndexFromMouseSelection: UseResetContainerRowIndexFromMouseSelectionHook = ({
  lastSelectedIndexWhenInputModeWasDefaultMap,
}) => {
  const dispatch = useDispatch();
  const containerIndexMap = useAppSelector(
    (state) => state.ui.containerIndexMap
  );
  // This logic is similar to OTTEpisodes.tsx, please update there if changes are made here
  const resetContainerRowIndexFromMouseSelection = useCallback(
    (containerId: string) => {
      if (ENABLE_OTT_MOUSE_EVENTS) {
        const currentIndex = containerIndexMap[containerId] || 0;
        if (
          currentIndex
          !== lastSelectedIndexWhenInputModeWasDefaultMap.current[containerId]
        ) {
          dispatch(
            setTileIndexInContainer({
              containerId,
              // set active gridIndex as an invalid value if cw prompt is active
              index:
                lastSelectedIndexWhenInputModeWasDefaultMap.current[
                  containerId
                ] || 0,
            })
          );
        }
      }
    },
    [containerIndexMap, dispatch, lastSelectedIndexWhenInputModeWasDefaultMap]
  );
  return resetContainerRowIndexFromMouseSelection;
};
