import LRUCache from '@adrise/utils/lib/lru';
import { InputDeviceType } from '@tubitv/analytics/lib/genericEvents';
import throttle from 'lodash/throttle';
import trim from 'lodash/trim';
import type { AnyAction } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import { getVideoResourceQueryParameters } from 'client/features/playback/props/query';
import getApiConfig from 'common/apiConfig';
import * as actionTypes from 'common/constants/action-types';
import * as constants from 'common/constants/constants';
import * as eventTypes from 'common/constants/event-types';
import type { ParentalRating } from 'common/constants/ratings';
import { WEB_ROUTES } from 'common/constants/routes';
import { isLoggedInSelector } from 'common/features/authentication/selectors/auth';
import type ApiClient from 'common/helpers/ApiClient';
import logger from 'common/helpers/logging';
import { shouldShowLinearContent } from 'common/selectors/experiments/liveNews';
import { enable4KSelector, enableHEVCSelector } from 'common/selectors/fire';
import { useHlsSelector as tizenUseHlsSelector } from 'common/selectors/tizen';
import type { TubiThunkDispatch, TubiThunkAction } from 'common/types/reduxThunk';
import type { Payload } from 'common/types/search';
import type StoreState from 'common/types/storeState';
import type { Video } from 'common/types/video';
import { actionWrapper } from 'common/utils/action';
import { buildSearchEventObject } from 'common/utils/analytics';
import { convertSeriesIdToContentId } from 'common/utils/dataFormatter';
import { getDebugLog } from 'common/utils/debug';
import { getImageQueryFromParams } from 'common/utils/imageResolution';
import { isParentalRatingOlderKidsOrLess } from 'common/utils/ratings';
import type { RawContainer } from 'common/utils/tensor';
import { clearAnonymousTokens, syncAnonymousTokensClient } from 'common/utils/token';
import { trackEvent } from 'common/utils/track';

import type { FetchWithTokenOptions } from './fetch';
import { fetchWithToken } from './fetch';
import { batchAddVideos } from './video';

export type SearchResponse = {
  containers: RawContainer[];
  contents: Record<string, Video>;
  personalization_id: string;
};

export type SearchContents = Video[];

/**
 * Set the index of the currently highlighted tile in the search results
 * @param {number | null} activeIdx The
 * @return {{activeIdx: number, type: string}}
 */
export function setActiveTileIndex(activeIdx: number | null) {
  return {
    type: actionTypes.SEARCH_SET_ACTIVE_IDX,
    activeIdx,
  };
}

let pendingSearches: (() => void)[] = [];

function removePendingSearch(abort: () => void): void {
  const pending = pendingSearches.indexOf(abort);
  /* istanbul ignore else */
  if (pending !== -1) {
    pendingSearches.splice(pending, 1);
  }
}

function abortPendingSearches(): void {
  for (const pendingSearch of pendingSearches) {
    pendingSearch();
  }
  pendingSearches = [];
}

function abortSearchesAndDispatch(actionType: typeof actionTypes.ABORT_SEARCH | typeof actionTypes.CLEAR_SEARCH): ThunkAction<Promise<void>, StoreState, ApiClient, AnyAction> {
  return (dispatch) => {
    abortPendingSearches();
    return dispatch(actionWrapper(actionType));
  };
}

export function abortSearch(): ThunkAction<Promise<void>, StoreState, ApiClient, AnyAction> {
  return abortSearchesAndDispatch(actionTypes.ABORT_SEARCH);
}

export function clearSearch(): ThunkAction<Promise<void>, StoreState, ApiClient, AnyAction> {
  return abortSearchesAndDispatch(actionTypes.CLEAR_SEARCH);
}

export function clearSearchStoreKeys(): ThunkAction<Promise<void>, StoreState, ApiClient, AnyAction> {
  return (dispatch) => {
    searchResultsCache.clear();
    return dispatch(actionWrapper(actionTypes.CLEAR_SEARCH_STORE_KEYS));
  };
}

export const searchStart = (key: string, path?: string) => ({ type: actionTypes.LOAD_SEARCH_START, key, path });

// FIXME: directKeyPressed, path and query are not consumed in the reducer. Need to review and clean up.
export function searchBy(payload: Payload): ThunkAction<Promise<void>, StoreState, ApiClient, AnyAction> {
  return (dispatch, getState, client) => {
    const search = {
      ...payload,
      key: payload.key.slice(0, constants.OTT_SEARCH_MAX_KEYWORD_LEN),
    };
    // starts the search process
    dispatch(actionWrapper(actionTypes.LOAD_SEARCH_EPIC, { key: search.key }));
    if (trim(search.key).length === 0) {
      return Promise.resolve();
    }
    return performSearch(dispatch, payload, getState(), client);
  };
}

export const setIsVoiceSearch = (isVoiceSearch: boolean) => {
  return {
    type: actionTypes.SET_IS_VOICE_SEARCH,
    result: isVoiceSearch,
  };
};

export const searchSuccess = (contentIds: string[], key: string, personalizationId: string) => {
  return {
    type: actionTypes.LOAD_SEARCH_SUCCESS,
    result: contentIds || [],
    key,
    personalizationId,
  };
};
export const searchFail = (key: string, error: Error) => actionWrapper(actionTypes.LOAD_SEARCH_FAIL, { error, key });

// export for test only
export const searchResultsCache = new LRUCache<{ observable: { promise: Promise<SearchResponse | undefined>, abort:() => void }; expiry: number }>(
  constants.SEARCH_RESULTS_CACHE_COUNT
);

export const isSearchResultsCacheEmpty = () => searchResultsCache.isEmpty();
export const addToSearchCache = (cacheKey: string, observable: { promise: Promise<SearchResponse | undefined>, abort: () => void }) => {
  const entry = { observable, expiry: Date.now() + constants.OTT_SEARCH_RESULTS_CACHE_DURATION };
  searchResultsCache.set(cacheKey, entry);
  return entry.observable;
};

const hasSearchCacheEntry = (cacheKey: string) => {
  const cacheEntry = searchResultsCache.peek(cacheKey);
  if (!cacheEntry) return false;
  return cacheEntry.expiry > Date.now();
};

// export for test only
export const getSearchCacheEntry = (cacheKey: string) => {
  const cacheEntry = searchResultsCache.get(cacheKey);
  if (!(hasSearchCacheEntry(cacheKey) && cacheEntry)) return undefined;
  return cacheEntry.observable;
};

const buildCacheKey = (key: string, query?: Record<string, unknown>) => {
  return [key, query].filter(Boolean).join('__');
};

const searchLog = getDebugLog('search');

/**
 * Add kidsMode as query param to search URL.
 * Set isKidsMode to false if parentalRating is 0/1 (little or older kids)
 * The reason being if kidsMode is set to true then UAPI will return incorrect movie ratings
 * for a given parental rating.
 */
export const getIsKidsMode = (parentalRating: ParentalRating, isKidsModeEnabled: boolean = false) => {
  return isParentalRatingOlderKidsOrLess(parentalRating) ? false : isKidsModeEnabled;
};

interface AddToCacheParams {
  cacheKey: string,
  client: ApiClient,
  key: string,
  query: Record<string, unknown>,
  isKidsModeEnabled?: boolean,
  parentalRating: ParentalRating,
  useLinearHeader: boolean,
  useAnonymousToken: boolean,
  isMobile?: boolean;
  state: StoreState;
  personalizationId?: string;
  dispatch: TubiThunkDispatch;
}

async function addToCache({
  cacheKey,
  client,
  key,
  isKidsModeEnabled,
  parentalRating,
  useLinearHeader,
  useAnonymousToken,
  isMobile,
  state,
  personalizationId,
  dispatch,
}: AddToCacheParams) {
  searchLog('Adding to cache:', cacheKey);
  const isKidsMode = getIsKidsMode(parentalRating, isKidsModeEnabled);

  // we would like to run the experiment in FIRETV
  const videoResourceParams = __OTTPLATFORM__ === 'FIRETV_HYB' ? await getVideoResourceQueryParameters({
    enableHEVC: enableHEVCSelector(state),
    enable4K: enable4KSelector(state),
    tizenUseHls: tizenUseHlsSelector(state),
  }) : {};

  const url = `${getApiConfig().searchServicePrefix}/api/v2/search`;

  const images = getImageQueryFromParams({
    isMobile,
    largerPoster: __ISOTT__,
  });

  /* eslint-disable-next-line compat/compat */
  const controller = new AbortController();

  const searchProps: FetchWithTokenOptions = {
    useAnonymousToken,
    errorLogLevel: 'warn',
    qsStringifyOptions: {
      arrayFormat: 'brackets',
    },
    params: {
      ...videoResourceParams,
      images,
      search: key,
      session_id: personalizationId,
      include_channels: true,
      include_linear: useLinearHeader && /* istanbul ignore next */ __IS_LIVE_NEWS_ENABLED__,
      is_kids_mode: isKidsMode,
    },
    controller,
  };

  return addToSearchCache(cacheKey, {
    promise: fetchWithToken<SearchResponse>(url, searchProps)(dispatch, () => state, client),
    abort: () => controller.abort(),
  });
}

// In the future, the search endpoint will support returning containers. The response structure now supports that even though the containers are not being returned.
// For now, we flatten the containers response.
export function flattenSearchResponse(result: SearchResponse) {
  const contents = result.containers.reduce((acc, { children }) => {
    if (children) {
      children.forEach(contentId => {
        if (contentId in result.contents) {
          acc.push(result.contents[contentId]);
        }
      });
    }
    return acc;
  }, [] as Video[]);
  return {
    contents,
    personalizationId: result.personalization_id,
  };
}

export function processSearchResponse(contents: SearchContents, deleteExistingVideos?: boolean) {
  // preprocess contents
  const contentIds = contents.map((content) => {
    const id = content.id;
    return content.type === constants.SERIES_CONTENT_TYPE ? convertSeriesIdToContentId(id) : id;
  });

  return {
    result: contentIds,
    action: batchAddVideos(contents, {
      deleteExistingVideos,
    }) as unknown as AnyAction,
  };
}

const getInputDeviceType = (directKeyPressed: boolean) =>
  directKeyPressed ? InputDeviceType.KEYBOARD : InputDeviceType.NATIVE;

const throttledSyncToken = throttle(
  () => {
    clearAnonymousTokens();
    syncAnonymousTokensClient();
  },
  10000,
  { leading: true, trailing: false }
);

/**
 * perform send search request
 * @param payload
 * @param state$ the store stream
 * @param client injected dependency, instance of ApiClient
 */
// export for test only
export const performSearch = async (dispatch: TubiThunkDispatch, payload: Payload, storeState: StoreState, client: ApiClient) => {
  const {
    userSettings: { parentalRating },
    ui: { isKidsModeEnabled, isMobile },
    search: { isVoiceSearch },
  } = storeState;
  const { key, path, query, directKeyPressed, personalizationId } = payload;
  const cacheKey = buildCacheKey(key, query);
  const cacheKeyTrimmed = buildCacheKey(trim(key), query);
  const hasCacheKey = hasSearchCacheEntry(cacheKey);
  const actualCacheKey = hasCacheKey ? cacheKey : cacheKeyTrimmed;
  const sameAsMostRecentCacheEntry = searchResultsCache.indexOf(actualCacheKey) === 0; // deliberately ignores expired check
  const searchQuery = {
    ...query,
  };

  let cacheEntry = getSearchCacheEntry(actualCacheKey);

  if (cacheEntry) {
    searchLog(`Found in cache: "${actualCacheKey}"`);
  } else {
    /* istanbul ignore if */
    if (pendingSearches.length) {
      abortPendingSearches();
    }

    cacheEntry = await addToCache({
      cacheKey: actualCacheKey,
      client,
      key,
      query: searchQuery,
      state: storeState,
      isKidsModeEnabled,
      parentalRating,
      useLinearHeader: shouldShowLinearContent(storeState),
      useAnonymousToken: !isLoggedInSelector(storeState),
      isMobile,
      personalizationId,
      dispatch,
    });
  }

  dispatch(searchStart(key, path));

  // if this is a new search query for the most recent search, don't clear the tile index, otherwise the previously
  // selected tile will be lost and the user will be annoyed.
  if (!sameAsMostRecentCacheEntry) {
    dispatch(setActiveTileIndex(null));
  }

  try {
    pendingSearches.push(cacheEntry.abort);

    const response = await cacheEntry.promise;
    removePendingSearch(cacheEntry.abort);
    if (response === undefined) {
      searchLog(`Aborted removing from cache: ${actualCacheKey}`);
      searchResultsCache.remove(actualCacheKey);
      return;
    }

    const searchContents = flattenSearchResponse(response);
    const { result, action } = processSearchResponse(searchContents.contents);
    const searchSuccessAction = searchSuccess(result, key, response.personalization_id);

    const searchEventBody = buildSearchEventObject(
      key,
      isVoiceSearch ? InputDeviceType.VOICE : getInputDeviceType(directKeyPressed)
    );
    trackEvent(eventTypes.SEARCH, searchEventBody);
    dispatch(action);
    dispatch(searchSuccessAction);
  } catch (error) {
    searchResultsCache.remove(actualCacheKey);
    // We only want to sync the token once, instead of every error when a user types character
    const errorCode = error.status || /* istanbul ignore next */ error.httpCode;
    /* istanbul ignore else */
    if (errorCode === 401 || /* istanbul ignore next */ errorCode === 403) {
      throttledSyncToken();
    }

    logger.error({ error, key }, 'error when loading data for Search container');
    dispatch(searchFail(key, error));
  }
};

/**
 * store src path, used to back to src path when exit search
 * @param path src page path, will only store non-search page
 */
export function storeSrcPath(path: string): TubiThunkAction {
  return (dispatch) => {
    if (path.indexOf(WEB_ROUTES.search) === 0) return Promise.resolve();

    return dispatch(actionWrapper(actionTypes.SEARCH_STORE_SRC_PATH, { fromPath: path }));
  };
}

/**
 * Set the indexes of the currently highlighted key in the keyboard grid
 * @param {number} rowIndex
 * @param {number} columnIndex
 * @return {{columnIndex: number, rowIndex: number, type: string}}
 */
export function setKeyboardIndexes(rowIndex: number, columnIndex: number) {
  return {
    type: actionTypes.SEARCH_SET_KEYBOARD_INDEXES,
    rowIndex,
    columnIndex,
  };
}

/**
 * Sets the active section on search page. Must be either keyboard, grid or categories
 * @param {*} activeSection
 * @returns
 */
export function setActiveSearchSection(activeSection: number) {
  return {
    type: actionTypes.SET_ACTIVE_SEARCH_SECTION,
    activeSection,
  };
}
