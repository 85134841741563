import { buildQueryString } from '@adrise/utils/lib/queryString';
import Cookie from 'react-cookie';

import { handleOTTOnboardingSkipped } from 'common/actions/fire';
import { NUM_SECONDS_IN_FIFTY_YEARS } from 'common/constants/constants';
import { COOKIE_SERVED_OTT_LANDING_PAGE } from 'common/constants/cookies';
import { OTT_ROUTES } from 'common/constants/routes';
import OTTAndroidTVRegistrationOnly from 'common/experiments/config/ottAndroidTVRegistrationOnly';
import OTTFireTVRemoveOnboarding from 'common/experiments/config/ottFireTVRemoveOnboarding';
import { isLoggedInSelector } from 'common/features/authentication/selectors/auth';
import { isGDPREnabledSelector } from 'common/features/gdpr/selectors/gdpr';
import tubiHistory from 'common/history';
import FeatureSwitchManager from 'common/services/FeatureSwitchManager';
import type { TubiStore } from 'common/types/storeState';
import { getCurrentPathname } from 'common/utils/getCurrentPathname';
import { routeContainsKidsMode } from 'common/utils/landingPageTools';
import { getOttOnboardingRoute } from 'ott/utils/onboarding';

export const setupOTTLandingPage = (store: TubiStore) => {
  const state = store.getState();
  const location = tubiHistory.getCurrentLocation();
  if (
    getCurrentPathname() !== OTT_ROUTES.home
      || routeContainsKidsMode(location.search)
      || isGDPREnabledSelector(state)
      || FeatureSwitchManager.isDisabled('LandingPage')
      || location.query.qaSuitest === 'true'
      || isLoggedInSelector(state)
  ) {
    return;
  }

  const servedLandingPageCookie: undefined | string = Cookie.load(COOKIE_SERVED_OTT_LANDING_PAGE);
  if (!servedLandingPageCookie) {
    Cookie.save(COOKIE_SERVED_OTT_LANDING_PAGE, 'true', {
      // User would not see the landing page again
      maxAge: NUM_SECONDS_IN_FIFTY_YEARS,
      path: '/',
    });

    const registrationOnlyExperiment = OTTAndroidTVRegistrationOnly(store);
    registrationOnlyExperiment.logExposure();
    const shouldShowRegistrationOnly = registrationOnlyExperiment.getValue();
    if (shouldShowRegistrationOnly) {
      tubiHistory.replace(`${OTT_ROUTES.landing}${buildQueryString(location.query)}`);
      return;
    }

    const removeOnboardingExperiment = OTTFireTVRemoveOnboarding(store);
    removeOnboardingExperiment.logExposure();
    const shouldRemoveOnboarding = removeOnboardingExperiment.getValue();
    if (shouldRemoveOnboarding) {
      store.dispatch(handleOTTOnboardingSkipped());
      return;
    }

    const redirectBaseUrl = getOttOnboardingRoute();
    tubiHistory.replace(`${redirectBaseUrl}${buildQueryString(location.query)}`);
  }
};
