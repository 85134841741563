import { days, secs } from '@adrise/utils/lib/time';
import type { ValueOf } from 'ts-essentials';

import { getLocalData, setLocalData } from 'client/utils/localDataStorage';
import {
  LD_DATE_AMAZON_SSO_PROMPT_FIRST_SEEN,
  LD_AMAZON_SSO_PROMPT_SHOWN,
  LD_DATE_ONETAP_FIRST_SEEN,
  LD_ONETAP_DISMISSED,
  LD_DATE_VIZIO_EMAIL_PREFILL_PROMPT_FIRST_SEEN,
  LD_VIZIO_EMAIL_PREFILL_PROMPT_SHOWN,
} from 'common/constants/constants';
import { timeDiffInDays } from 'common/utils/date';

const SSO_PROMPT_ENABLED_PLATFORMS = ['FIRETV_HYB', 'ANDROIDTV', 'VIZIO'] as const;

type SSOPromptEnabledPlatforms = ValueOf<typeof SSO_PROMPT_ENABLED_PLATFORMS>;

export function isSSOPromptEnabledPlatform(platform: OTTPLATFORM | SSOPromptEnabledPlatforms): platform is SSOPromptEnabledPlatforms {
  return SSO_PROMPT_ENABLED_PLATFORMS.includes(platform as SSOPromptEnabledPlatforms);
}

const COOLDOWN_PERIOD = days(5); // Only show prompt at most every 5 days
const ENABLED_PERIOD = days(30); // Stop showing the prompt after 30 daysz

const PROMPT_HAS_SHOWN_FLAG = 'true';

const SSO_PROMPT_FIRST_SEEN: Record<SSOPromptEnabledPlatforms, string> = {
  FIRETV_HYB: LD_DATE_AMAZON_SSO_PROMPT_FIRST_SEEN,
  ANDROIDTV: LD_DATE_ONETAP_FIRST_SEEN,
  VIZIO: LD_DATE_VIZIO_EMAIL_PREFILL_PROMPT_FIRST_SEEN,
} as const;

const SSO_PROMPT_SHOWN: Record<SSOPromptEnabledPlatforms, string> = {
  FIRETV_HYB: LD_AMAZON_SSO_PROMPT_SHOWN,
  ANDROIDTV: LD_ONETAP_DISMISSED,
  VIZIO: LD_VIZIO_EMAIL_PREFILL_PROMPT_SHOWN,
};

export const setSSOPromptFirstSeenLocalData = (platform: SSOPromptEnabledPlatforms) => setLocalData(SSO_PROMPT_FIRST_SEEN[platform], String(Date.now()));

export const getSSOPromptFirstSeenDate = (platform: SSOPromptEnabledPlatforms) => Number(getLocalData(SSO_PROMPT_FIRST_SEEN[platform]) || undefined);

export const isInSSOPromptEnabledPeriod = (firstSeenDate: number) => timeDiffInDays(new Date(), new Date(firstSeenDate)) <= (ENABLED_PERIOD / days(1));

export const setSSOPromptShownLocalData = (platform: SSOPromptEnabledPlatforms) => setLocalData(SSO_PROMPT_SHOWN[platform], PROMPT_HAS_SHOWN_FLAG, COOLDOWN_PERIOD / secs(1));

export const isSSOPromptShownRecently = (platform: SSOPromptEnabledPlatforms) => getLocalData(SSO_PROMPT_SHOWN[platform]) === PROMPT_HAS_SHOWN_FLAG;
