import jwt_decode from 'jwt-decode';
import { v4 as uuid } from 'uuid';

import { getCookieCrossPlatform, setCookie } from 'client/utils/localDataStorage';
import { COOKIE_DEVICE_ID, NUM_SECONDS_IN_FIFTY_YEARS } from 'common/constants/constants';
import { setDeviceId as setDeviceIdAction } from 'common/features/authentication/actions/auth';
import type { TubiStore } from 'common/types/storeState';
import { getRefreshTokenFromStorage, setDeviceIdForTokenRequests } from 'common/utils/token';

const setDeviceId = (store: TubiStore, deviceId: string, { persist = true }: {persist?: boolean} = {}) => {
  setDeviceIdForTokenRequests(deviceId);
  if (persist) {
    setCookie(COOKIE_DEVICE_ID, deviceId, NUM_SECONDS_IN_FIFTY_YEARS);
  }
  store.dispatch(setDeviceIdAction(deviceId));
};

export const getDeviceIdFromAuthToken = (store: TubiStore) => {
  const state = store.getState();
  const { auth } = state;
  const isLoggedIn = !!auth.user;
  const token = isLoggedIn ? auth.user?.token : getRefreshTokenFromStorage();
  if (token) {
    return jwt_decode<any>(token).device_id;
  }
  return undefined;
};

export const setupDeviceId = async (store: TubiStore) => {
  let deviceId;
  try {
    deviceId = await getCookieCrossPlatform(COOKIE_DEVICE_ID);
  } catch {
    // If we're here, it's likely because the /oz/tizen/cookie/*
    // endpoint failed.

    // Try to read the deviceId from the token if we failed to
    // read it from the cookie (e.g. like if the /oz/tizen/cookie endpoint
    // fails)
    deviceId = getDeviceIdFromAuthToken(store);

    // Set the deviceId in the store. If we didn't find a device id on a user
    // token, just use a temporary one. We don't want to persist it though
    // because:
    // * the other endpoint failed so this one probably will too
    // * if we used a temporary one, we don't want to overwrite
    //   the user's original deviceId.
    setDeviceId(store, deviceId ?? uuid(), { persist: false });
    return;
  }

  const storedDeviceId = store.getState().auth.deviceId;

  if (!deviceId) {
    setDeviceId(store, uuid());
  } else if (deviceId !== storedDeviceId) {
    // The failsafe page comes with a deviceId in the store state that was
    // generated at compile time. This deviceId will need to be replaced, so
    // replace the mismatched deviceId in the store with the correct deviceId
    // in the cookie.
    setDeviceId(store, deviceId);
  }
};
