import { mins, secs } from '@adrise/utils/lib/time';
import parseUserAgent from '@adrise/utils/lib/ua-parser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ReferredType } from '@tubitv/analytics/lib/baseTypes';
import { ToastContainer, ToastProvider, ButtonAnimationContext, AnimatedToastContainer } from '@tubitv/ott-ui';
import classNames from 'classnames';
import type { Location, Query } from 'history';
import hoistNonReactStatics from 'hoist-non-react-statics';
import type { ErrorInfo, PropsWithChildren, RefObject } from 'react';
import React, { Component, Suspense, useState } from 'react';
import Cookie from 'react-cookie';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';

import { trackPlaybackCapabilities } from 'client/features/playback/track/client-log/trackPlaybackCapabilities';
import systemApi from 'client/systemApi';
import type SamsungSystemApi from 'client/systemApi/tizen';
import type { HybDeviceInfo } from 'client/systemApi/types';
import { hasExitFunction, hasExitApi } from 'client/systemApi/utils';
import { supportsVoiceViewAccessibility } from 'client/utils/clientTools';
import { isLongPressEventEnabled } from 'client/utils/isLongPressEventEnabled';
import { getLocalData, removeLocalData } from 'client/utils/localDataStorage';
import { reportAppLaunchTiming, reportAppStartUpTiming, reportWebViewReadyToUseTiming } from 'client/utils/performance';
import { setData as setSessionData } from 'client/utils/sessionDataStorage';
import { batchFavoriteChannel } from 'common/actions/epg';
import { loadHistory } from 'common/actions/loadHistory';
import { hideModal } from 'common/actions/modal';
import { loadCaptionSettingsFromLocal } from 'common/actions/ottCaptionSettings';
import { setOTTVideoPreview, setOTTAutostartVideoPreview, setOTTPromptAutostartVideoPreview, setOTTAutoplayVideoPreview } from 'common/actions/ottUI';
import { loadQueue } from 'common/actions/queue';
import { loadReminder } from 'common/actions/reminder';
import { resetApp, resetAppIfStale } from 'common/actions/resetApp';
import { setWaitingOnVoiceCommand, decreaseScreensaverCounter } from 'common/actions/ui';
import { loadUserSettings } from 'common/actions/userSettings';
import PreloadBundle from 'common/components/PreloadBundle/PreloadBundle';
import Spinner from 'common/components/uilib/Spinner/Spinner';
import { CLEAR_SCREENSAVER_COUNTER, UPDATE_CURRENT_DATE } from 'common/constants/action-types';
import {
  ENABLE_OTT_MOUSE_EVENTS,
  IS_PLATFORM_SUPPORT_SCREENSAVER,
  SHOULD_FETCH_DATA_ON_SERVER,
  LD_DEFAULT_AUTOSTART_VIDEO_PREVIEW,
  LD_DEFAULT_PROMPT_AUTOSTART_VIDEO_PREVIEW,
  LD_DEFAULT_VIDEO_PREVIEW,
  LD_FAVORITE_CHANNELS,
  LINEAR_CONTENT_TYPE,
  LD_DEFAULT_AUTOPLAY_VIDEO_PREVIEW,
  CUSTOM_EVENT_NAME,
  CUSTOM_EVENT_TYPES,
} from 'common/constants/constants';
import * as eventTypes from 'common/constants/event-types';
import { PLATFORMS } from 'common/constants/platforms';
import { OTT_ROUTES } from 'common/constants/routes';
import { withReactRouterModernContextAdapter } from 'common/context/ReactRouterModernContext';
import OTTAndroidtvIntroVideoFps from 'common/experiments/config/ottAndroidtvIntroVideoFps';
import OttFireTVAutocomplete from 'common/experiments/config/ottFireTVAutocomplete';
import OttFireTVRTU from 'common/experiments/config/ottFireTVRTU';
import type ExperimentManager from 'common/experiments/ExperimentManager';
import { load as loadAuth } from 'common/features/authentication/actions/auth';
import { isLoggedInSelector } from 'common/features/authentication/selectors/auth';
import { isCoppaEnabledSelector } from 'common/features/coppa/selectors/coppa';
import {
  sendQueuedAnalyticsEventWhenConsentReady,
} from 'common/features/gdpr/onetrust/utils';
import { isGDPREnabledSelector } from 'common/features/gdpr/selectors/gdpr';
import { LivePlaybackProvider } from 'common/features/playback/components/LivePlaybackProvider/LivePlaybackProvider';
import ThemeProvider from 'common/features/theme/ThemeProvider';
import logger from 'common/helpers/logging';
import tubiHistory from 'common/history';
import withExperiment from 'common/HOCs/withExperiment';
import withYouboraExperimentMapProvider from 'common/HOCs/withYouboraExperimentMapProvider';
import { isPlaybackDeeplinkSelector } from 'common/selectors/deepLink';
import { ottFireTVNewCategoryPageSelector } from 'common/selectors/experiments/ottFireTVNewCategoryPage';
import { ottShowMetadataOnSearchSelector } from 'common/selectors/experiments/ottShowMetadataOnSearchSelector';
import {
  activeVideoSelector,
  isSidePanelActiveSelector,
  isVideoPreviewEnabledSelector,
  isContainerVideoPreviewEnabledSelector,
  shouldRenderIntroVideoSelector,
} from 'common/selectors/ottUI';
import { isScreensaverVisibleSelector } from 'common/selectors/ui';
import trackingManager from 'common/services/TrackingManager';
import type { AppVersion } from 'common/types/fire';
import type { TubiThunkDispatch } from 'common/types/reduxThunk';
import type StoreState from 'common/types/storeState';
import { actionWrapper } from 'common/utils/action';
import { tryTwice } from 'common/utils/actionThunk';
import type { ReferredCtx } from 'common/utils/analytics';
import { buildReferredEventObject } from 'common/utils/analytics';
import { BgPageType, getPageType } from 'common/utils/backgroundImages';
import {
  addEventListener,
  dispatchBackEvent,
  handleXboxoneLeftStickKeyMapping,
  prependEventListener,
  removeEventListener,
} from 'common/utils/dom';
import { shouldFireActiveEventOnVisibilityChange } from 'common/utils/hybAppUtils';
import { getOTTRemote } from 'common/utils/keymap';
import { isPlatform } from 'common/utils/platform';
import { alwaysResolve } from 'common/utils/promise';
import { isKidsModeOrIsParentalRatingOlderKidsOrLess } from 'common/utils/ratings';
import { getReferredExtraCtxFromQuery } from 'common/utils/track';
import { isDetailsPageUrl, isOTTLiveNewsUrl, isOTTPlayerUrl } from 'common/utils/urlPredicates';
import OTTBackgroundWrapper from 'ott/components/OTTBackgroundWrapper/OTTBackgroundWrapper';
import OTTScreensaver from 'ott/components/OTTScreensaver/OTTScreensaver';
import TalkbackWarning from 'ott/components/TalkbackWarning';
import TubiSplash from 'ott/components/TubiSplash/TubiSplash';
import VoiceView from 'ott/components/VoiceView/VoiceView';
import { REACT_APP_RENDERED_TS } from 'ott/constants/tracking';
import { TTS_COOKIE_NAME, TTS_MODE } from 'ott/containers/Dev/TextToSpeech/TextToSpeech';
import FatalErrorMessage from 'ott/containers/FatalErrorMessage/FatalErrorMessage';
import OTTNotFound from 'ott/containers/OTTNotFound/OTTNotFound';
import {
  getUserSessionFromLocalStorage,
  isRedisPermitted,
  removeUserSessionFromLocalStorage,
  trackUserSessionLogging,
} from 'ott/features/authentication/utils/userSession';
import { waitUntilUserGiveConsent } from 'ott/features/gdpr/onetrust';
import { dispatchBackEventForOnetrust } from 'ott/features/gdpr/utils/dispatchBackEvent';
import MobileToOTTSignInToast from 'ott/features/mobileToOTTBridge/MobileToOTTSignInToast';
import { isComingSoonSelector } from 'ott/features/playback/selectors/vod';
import { getPlayableMediaTypes } from 'ott/utils/getPlayableMediaTypes';
import withPlayerContextProvider from 'src/common/HOCs/withPlayerContextProvider';

import styles from './App.scss';
import { hybCDM } from './cdm';
import { useSamsungPMR, useSamsungCW } from './hooks';

const ReactQueryProvider = ({ children }: PropsWithChildren) => {
  const queryClient = useState(() => new QueryClient())[0];
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

const REMOTE = getOTTRemote();

const LazyOTTLivePlayer = React.lazy(/* istanbul ignore next */() => import(/* webpackChunkName: "ott-live-player" */ 'ott/features/playback/components/OTTLivePlayer/OTTLivePlayer'));

const LazyExposureLogOverlay = React.lazy(/* istanbul ignore next */() => import(/* webpackChunkName: "exposure-log-overlay" */ 'common/components/ExposureLogOverlay/ExposureLogOverlay'));

const LazyMemoryUsageOverlay = React.lazy(/* istanbul ignore next */() => import(/* webpackChunkName: "memory-usage-overlay" */ 'client/features/MemoryUsageOverlay/MemoryUsageOverlay'));

const LazyOTTAppModal = React.lazy(/* istanbul ignore next */() => import(/* webpackChunkName: "ott-app-modal" */ 'ott/components/OTTAppModal/OTTAppModal'));

const EmptyComponentWithSamsungHooks = () => {
  useSamsungPMR();
  useSamsungCW();
  return null;
};

interface StateProps {
  appResetInProgress: boolean;
  hotDeeplinkInProgress: boolean;
  banBackButton: boolean;
  hasExitApi: boolean;
  isComingSoon: boolean;
  isContentNotFound: boolean;
  isPlaybackDeepLinked: boolean;
  isNavEnabled: boolean;
  isServiceUnavailable: boolean;
  isRemoteDisabled: boolean;
  isKidsModeEnabled: boolean;
  isKidsModeOrIsParentalRatingKids: boolean;
  isScreensaverVisible: boolean;
  isCoppaEnabled: boolean;
  pathname: string;
  query: Query;
  showLiveVideoBackground: boolean;
  enablePreviewVideoBackground: boolean;
  shouldPausePreviewVideo: boolean;
  voiceViewMode: string;
  waitingOnVoiceCommand?: boolean;
  isLoggedIn: boolean;
  appVersion: Partial<AppVersion>;
  isShowingModal: boolean;
  isSlowDevice: boolean;
  isGDPREnabled: boolean;
  isIntroRendering: boolean;
}

type RouteProps = Pick<RouteComponentProps<any, any>, 'location' | 'params'>;

interface OwnProps extends RouteProps {
  dispatch: TubiThunkDispatch;
  ottAndroidtvIntroVideoFps: ReturnType<typeof OTTAndroidtvIntroVideoFps>;
  ottFireTVAutocomplete: ReturnType<typeof OttFireTVAutocomplete>;
  ottFireTVRTU: ReturnType<typeof OttFireTVRTU>;
}

export type Props = PropsWithChildren<OwnProps & StateProps>;

interface State {
  renderFallbackPage: boolean;
  pathnameRedirectFromScreensaver: string;
  error?: Error;
  errorInfo?: ErrorInfo;
  shouldShowConsent: boolean;
}
export class App extends Component<React.PropsWithChildren<Props>, State> {
  static fetchData = fetchData;

  static fetchDataDeferred = fetchDataDeferred;

  oneTrustRootRef: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    // get user CC settings from cookie/localstorage and store in redux
    // TODO: (yuhao) We should avoid call "dispatch" in constructor, going to remove this once we get value from UAPI
    if (__CLIENT__) {
      props.dispatch(loadCaptionSettingsFromLocal());
      props.dispatch(setOTTVideoPreview(getLocalData(LD_DEFAULT_VIDEO_PREVIEW) !== 'false'));
      props.dispatch(setOTTAutostartVideoPreview(getLocalData(LD_DEFAULT_AUTOSTART_VIDEO_PREVIEW) !== 'false'));
      props.dispatch(setOTTAutoplayVideoPreview(getLocalData(LD_DEFAULT_AUTOPLAY_VIDEO_PREVIEW) !== 'false'));
      props.dispatch(setOTTPromptAutostartVideoPreview(getLocalData(LD_DEFAULT_PROMPT_AUTOSTART_VIDEO_PREVIEW) === 'true'));
    }
    this.state = {
      renderFallbackPage: false,
      pathnameRedirectFromScreensaver: '', // the url of the detail page of content being shown in the Samsung screensaver
      shouldShowConsent: props.isGDPREnabled,
    };

    this.oneTrustRootRef = React.createRef();
  }

  private isScreensaverEnabled: boolean = false;

  private screensaverTimer: ReturnType<typeof setInterval> | undefined;

  private awayFromAppTimestamp: number | undefined;

  private includeVoiceView = supportsVoiceViewAccessibility();

  private updateTimeTimerId: number | undefined;

  private enabledButtonAnimation = isLongPressEventEnabled();

  // This is to only prevent React from getting into an inconsistent state when it doesn't know what to render due to an error.
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Display fallback UI
    this.setState({ renderFallbackPage: true, error, errorInfo });
    // log error here
    logger.fatal({ error, errorInfo }, `Client side rendering failure: ${error?.message || 'unknown error'}`);
  }

  reportAppLaunchTiming() {
    hybCDM(this.props.dispatch, (deviceInfo: HybDeviceInfo) => {
      reportAppLaunchTiming(deviceInfo);
      this.sendAppStartUpTiming();
    });
  }

  /**
   * mobile is detected and dispatched to reflect it on state
   */
  componentDidMount() {
    if (this.props.isGDPREnabled) {
      waitUntilUserGiveConsent(this.oneTrustRootRef?.current).then(() => {
        this.setState({
          shouldShowConsent: false,
        });
      });
    }

    const {
      isLoggedIn,
      isKidsModeOrIsParentalRatingKids,
      ottAndroidtvIntroVideoFps,
      isIntroRendering,
    } = this.props;

    setSessionData(REACT_APP_RENDERED_TS, String(Date.now()));

    this.reportRTUTime();

    if (!__IS_HYB_APP__) {
      this.sendAppStartUpTiming();
    }

    /* istanbul ignore next */
    if (__OTTPLATFORM__ === 'FIRETV_HYB') {
      if (isLoggedIn) {
        // notify native if device is in kids mode or parental controls are under older kids
        systemApi.notifyKidsModeChangeEvent?.(isKidsModeOrIsParentalRatingKids);
      }
      systemApi.setDefaultCaptionStyleFromSystemSetting?.();
    }

    ottAndroidtvIntroVideoFps.logExposure();

    this.updateCurrentDateByMinute();

    // place keydown handler at the front of handler queue
    prependEventListener(window, 'keydown', this.keydownEntryHandler);

    if (IS_PLATFORM_SUPPORT_SCREENSAVER) {
      this.isScreensaverEnabled = true;
      this.resetScreensaverTimer();
    }

    if (this.shouldEnableMouseEvents()) {
      systemApi.addMouseCursorChangeEventListener();
    }

    // For apps requiring support of a hard exit on native exit button press on their remote
    if (__OTTPLATFORM__ === 'VIZIO') {
      addEventListener(window, 'keydown', this.handleExitPress);
    }

    // keyup to be consistent with other OTT behavior
    addEventListener(window, 'keyup', this.handleKeyUp);

    // some platforms may handle history based on keypress or keydown. e.g. Sony
    addEventListener(window, 'keypress', this.ignoreKeyEventForBackKeyCode);
    addEventListener(window, 'keydown', this.ignoreKeyEventForBackKeyCode);

    addEventListener(window, CUSTOM_EVENT_NAME, this.handleCustomEvent);

    // has to be on document and it unlike the similar event listener in OTTPlayer this will be for longer idle time and app-wide
    // @link https://adrise.atlassian.net/browse/CLIEN-2621 & https://developer.amazon.com/public/solutions/platforms/webapps/docs/getting-started-with-web-apps-for-fire-tv
    if (__IS_ANDROIDTV_HYB_PLATFORM__
      || isPlatform([PLATFORMS.firetv_hyb, PLATFORMS.tizen, PLATFORMS.sony])) {
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }

    // NOTE Samsung uses a loader to get all resources, not from server rendering
    // so here we add `is-ott` className to `html` element
    if (__OTTPLATFORM__ === 'TIZEN') {
      document.documentElement.className = `${document.documentElement.className || ''} is-ott`;
    }

    this.syncFavoritedChannel();

    systemApi.updateCWonDevice({ shouldSendEvent: __OTTPLATFORM__ === 'COMCAST' && isLoggedIn });

    this._sendActiveAndReferredEvents();

    // log a warning in the console if trying to debug voice view but voice isn't supported on this platform
    const inTTSDevMode = this.props.voiceViewMode !== TTS_MODE.default;
    if (inTTSDevMode && !this.includeVoiceView) {
      // lazy load the debug log module because it will rarely be needed
      import('common/utils/debug').then(({ getDebugLog }) => {
        getDebugLog('App')('WARNING: Unable to debug voice view because voice view is disabled for this platform.');
      });
    }

    // Preload the bundle so that the transition to the details page could be more quickly for the first time
    import(/* webpackChunkName: "ott-details", webpackPrefetch: true*/ 'ott/containers/OTTDetails/OTTDetails');

    this.logPlayableMediaTypes();

    if (!isIntroRendering) {
      this.reportAppLaunchTiming();
    }
  }

  reportRTUTime() {
    const { ottFireTVRTU } = this.props;
    ottFireTVRTU.logExposure();
    if (!__IS_HYB_APP__) {
      reportWebViewReadyToUseTiming({ rtuEndTime: Date.now() });
    } else {
      systemApi.reportWebViewReady();
    }
  }

  _sendActiveAndReferredEvents() {
    const _send = () => {
      this._sendReferredEvent();
      sendQueuedAnalyticsEventWhenConsentReady(this.props.isGDPREnabled);
    };
    if (window._setUpPromises) {
      window._setUpPromises.then(() => {
        _send();
      }).catch(() => {
        logger.error('Failed to set up advertiser id');
        _send();
      });
    } else {
      _send();
    }
  }

  // Log user agent playable media types (shortcut ticket #639612)
  logPlayableMediaTypes = async () => {
    await Promise.resolve();
    const playableMediaTypes = getPlayableMediaTypes();
    const ua = parseUserAgent();
    trackPlaybackCapabilities({ playableMediaTypes, ua });
  };

  /**
   * Build and send the referred event based on the page
   */
  _sendReferredEvent = () => {
    const { query, pathname, isComingSoon } = this.props;
    const referredExtraCtx = getReferredExtraCtxFromQuery(query);
    if (referredExtraCtx) {
      let trackValue: ReferredType = !__IS_ANDROIDTV_HYB_PLATFORM__ && __OTTPLATFORM__ !== 'FIRETV_HYB' ? eventTypes.DEEPLINK_V2 : eventTypes.REFERRAL_V2;
      // when cast mobile device to OTT device, send `source_device_id` & `source_platform`
      if (query.from_device_id && query.from_platform) {
        trackValue = eventTypes.REMOTE_DEVICE;
        (referredExtraCtx as ReferredCtx).source_device_id = query.from_device_id;
        (referredExtraCtx as ReferredCtx).source_platform = (query.from_platform as string).toUpperCase();
      }
      const { searchKey } = query;
      const extraCtx = { query: searchKey as string || undefined, isUpcoming: isComingSoon };
      trackingManager.addEventToQueue(eventTypes.REFERRED, buildReferredEventObject(pathname, referredExtraCtx as ReferredCtx, trackValue, extraCtx));
    } else if (__OTTPLATFORM__ === 'LGTV') {
      // LGTV JS API to capture a launch reason response value
      // undocumented api from LG which will contain values like `launcher` `store` `bannerAd`
      if (typeof window !== 'undefined' && (window as any).webOSSystem?.launchReason) {
        const referredExtraCtx = {
          source: (window as any).webOSSystem.launchReason,
          medium: 'lg-launcher',
        };
        trackingManager.addEventToQueue(eventTypes.REFERRED, buildReferredEventObject(pathname, referredExtraCtx as ReferredCtx, 'UNKNOWN'));
      }
    }
  };

  componentDidUpdate(prevProps: Props) {
    const {
      isKidsModeEnabled,
      pathname,
      isShowingModal,
      showLiveVideoBackground,
      enablePreviewVideoBackground,
      isIntroRendering,
    } = this.props;

    if (__OTTPLATFORM__ === 'TIZEN' && isKidsModeEnabled !== prevProps.isKidsModeEnabled) {
      (systemApi as SamsungSystemApi).updatePreview();
      (systemApi as SamsungSystemApi).updateContinueWatchingRow();
    }

    if (this.isScreensaverEnabled) {
      if (this.shouldStopScreensaverTimer()) {
        this.stopScreensaverTimer();
      } else if (
        (!isShowingModal && prevProps.isShowingModal)
        || (!showLiveVideoBackground && prevProps.showLiveVideoBackground)
        || (!enablePreviewVideoBackground && prevProps.enablePreviewVideoBackground)
        || pathname !== prevProps.pathname
      ) {
        this.resetScreensaverTimer();
      }
    }
    if (prevProps.isIntroRendering && !isIntroRendering) {
      this.reportAppLaunchTiming();
    }
  }

  componentWillUnmount() {
    clearInterval(this.screensaverTimer as ReturnType<typeof setTimeout>);

    document.removeEventListener('visibilitychange', this.handleVisibilityChange);

    removeEventListener(window, 'keydown', this.handleExitPress);
    removeEventListener(window, 'keydown', this.keydownEntryHandler);
    removeEventListener(window, 'keydown', this.ignoreKeyEventForBackKeyCode);
    removeEventListener(window, 'keypress', this.ignoreKeyEventForBackKeyCode);
    removeEventListener(window, 'keyup', this.handleKeyUp);
    removeEventListener(window, CUSTOM_EVENT_NAME, this.handleCustomEvent);

    // istanbul ignore else
    if (this.updateTimeTimerId) {
      clearInterval(this.updateTimeTimerId);
    }

    if (this.shouldEnableMouseEvents()) {
      systemApi.removeMouseCursorChangeEventListener();
    }
  }

  updateCurrentDateByMinute = () => {
    const { dispatch } = this.props;
    dispatch(actionWrapper(UPDATE_CURRENT_DATE));
    const offset = 60 - new Date().getSeconds();
    this.updateTimeTimerId = window.setTimeout(() => {
      dispatch(actionWrapper(UPDATE_CURRENT_DATE));
      // istanbul ignore next
      this.updateTimeTimerId = window.setInterval(() => {
        dispatch(actionWrapper(UPDATE_CURRENT_DATE));
      }, mins(1));
    }, offset * secs(1));
  };

  // we stored the data in the local storage first
  // now we need to sync the data with the server
  // we can delete it like 6 month later when most of the users have synced
  syncFavoritedChannel = () => {
    const { dispatch } = this.props;
    let localFavorites;
    try {
      localFavorites = JSON.parse(getLocalData(LD_FAVORITE_CHANNELS) || '[]');
    } catch (e) {
      localFavorites = [];
    }
    if (localFavorites.length) {
      dispatch(batchFavoriteChannel(localFavorites));
      removeLocalData(LD_FAVORITE_CHANNELS);
    }
  };

  shouldEnableMouseEvents = () => {
    return ENABLE_OTT_MOUSE_EVENTS;
  };

  sendAppStartUpTiming = () => {
    if (!this.props.isPlaybackDeepLinked) {
      reportAppStartUpTiming();
    }
  };

  closeModal = () => {
    this.props.dispatch(hideModal());
  };

  /**
   * this handler is inserted in front of all other keydown handlers, use it to disable keydowns or take some early action
   * @param e
   */
  keydownEntryHandler = (e: KeyboardEvent) => {
    const { isRemoteDisabled, isIntroRendering } = this.props;
    if (isRemoteDisabled || isIntroRendering) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  };

  /**
   * Used by keypress to prevent handling of back keycode as we handle back on keyup
   */
  ignoreKeyEventForBackKeyCode = (e: KeyboardEvent) => {
    if (e.keyCode !== REMOTE.back) return;

    e.preventDefault();
  };

  /**
   * intercept back button on remotes and handle manually
   * if we are on home grid, let the default event propagate
   * else prevent that default event, and dispatch our own
   */
  handleKeyUp = (e: KeyboardEvent) => {
    const {
      pathname,
      banBackButton,
      isScreensaverVisible,
    } = this.props;

    const { keyCode } = e;

    if (this.isScreensaverEnabled) {
      this.handleScreensaverKeyup(e);
      // Should not respond to other events when the screensaver is visible
      if (isScreensaverVisible) return;
    }

    const isHome = pathname === OTT_ROUTES.home;

    if (handleXboxoneLeftStickKeyMapping(e)) {
      return;
    }

    if (keyCode !== REMOTE.back || banBackButton) return;

    if (this.state.shouldShowConsent) {
      dispatchBackEventForOnetrust(e);
      return;
    }

    const dispatchedBackEvent = dispatchBackEvent();

    if (!dispatchedBackEvent) {
      e.preventDefault();
      return;
    }

    if (isHome) {
      e.preventDefault();
      return;
    }

    // not home page and not cancelled, go back in browser history
    e.preventDefault();
    tubiHistory.goBack();
  };

  // If you add a new condition here to stop screensaver timer,
  // you should also add the corresponding condition to reset the screensaver timer in the cDU
  shouldStopScreensaverTimer = () => {
    const { isShowingModal, pathname, showLiveVideoBackground } = this.props;
    // TODO: We need to handle the video preview feature when we graduate it
    if (isShowingModal || isOTTPlayerUrl(pathname) || showLiveVideoBackground || pathname === OTT_ROUTES.ageUnavailable) {
      return true;
    }
    return false;
  };

  stopScreensaverTimer = () => {
    const { dispatch, isScreensaverVisible } = this.props;

    if (isScreensaverVisible) {
      dispatch(actionWrapper(CLEAR_SCREENSAVER_COUNTER));
    }

    if (this.screensaverTimer) {
      clearInterval(this.screensaverTimer as ReturnType<typeof setTimeout>);
    }
  };

  shouldResetScreensaverTimerWhenKeyup = (keyCode: number) => {
    const { pathname, isScreensaverVisible } = this.props;
    const { pathnameRedirectFromScreensaver } = this.state;
    const isSamsungScreensaverVisible = isScreensaverVisible && __OTTPLATFORM__ === 'TIZEN'; // true if the screensaver is visible on Samsung

    if (isSamsungScreensaverVisible && (keyCode === REMOTE.arrowLeft || keyCode === REMOTE.arrowRight)) {
      return false;
    }

    // should not rest the Screensaver Timer when the user pressing the play/pause button in the image being displayed on Samsung’s screensaver
    // and the Screensaver Timer will be reset after the new page loaded successfully (in cDU)
    const isPlayButton = keyCode === REMOTE.play || keyCode === REMOTE.playPause;
    const willEnterNewPage = pathname !== pathnameRedirectFromScreensaver;
    if (isSamsungScreensaverVisible && isPlayButton && willEnterNewPage) {
      return false;
    }

    return true;
  };

  /**
   * will reset the screensaver counter if the screen is not idled
   */
  resetScreensaverTimer = () => {
    const { dispatch } = this.props;

    // reset counter in reducer to threshold (5)
    dispatch(actionWrapper(CLEAR_SCREENSAVER_COUNTER));

    // reset the interval
    clearInterval(this.screensaverTimer as ReturnType<typeof setTimeout>);
    // every one minute, decrease counter in reducer by 1
    this.screensaverTimer = setInterval(() => {
      dispatch(decreaseScreensaverCounter());
    }, 60 * 1000);
  };

  handleCustomEvent = async ({ detail }: CustomEvent) => {
    const { dispatch, location } = this.props;
    const { type } = detail;

    if ([CUSTOM_EVENT_TYPES.USER_NOT_FOUND, CUSTOM_EVENT_TYPES.LOGIN_REQUIRED].includes(type)) {
      if (type === CUSTOM_EVENT_TYPES.LOGIN_REQUIRED) {
        if (!isRedisPermitted()) {
          trackUserSessionLogging({
            message: 'LoginRequired: Redis is not permitted',
            loggerConfig: {
              data: {
                userSession: await getUserSessionFromLocalStorage(),
                originalUrl: detail.originalUrl,
              },
            },
          });
          return;
        }

        trackUserSessionLogging({
          message: 'LoginRequired: req.user not found but a user session exists in localStorage',
          loggerConfig: {
            data: {
              userSession: await getUserSessionFromLocalStorage(),
            },
          },
        });
        await removeUserSessionFromLocalStorage();
      }

      const currentUrl = [location.pathname, location.search].filter(Boolean).join('');
      return dispatch(resetApp(location, currentUrl));
    }
  };

  handleScreensaverKeyup = (e: KeyboardEvent) => {
    const { keyCode } = e;

    if (this.shouldStopScreensaverTimer()) {
      return;
    }

    if (this.shouldResetScreensaverTimerWhenKeyup(keyCode)) {
      this.resetScreensaverTimer();
    }
  };

  handleExitPress = (e: KeyboardEvent) => {
    if (e.keyCode !== REMOTE.exit || !hasExitFunction()) return;
    systemApi.exit!();
  };

  /**
   * Handling the OTT app going into background such as FireTV use of Alexa voice command and other scenarios
   * When the app has been in the background for more than 3 hours we will refresh it and send to homepage upon being active again
   * App can only be refreshed when it is in foreground so we wait for user to return to refresh
   */
  handleVisibilityChange = (event: Event) => {
    if (document.hidden) {
      // app is in the background
      this.awayFromAppTimestamp = Date.now();
      trackingManager.onAppInactive();
    } else {
      // app is in the foreground
      const { dispatch, waitingOnVoiceCommand, location } = this.props;
      // There is a bug on the FireTV device, they will focus to another element when the page is shown again

      // When the app comes from background to foreground then
      // we need to trigger an active event.
      if (shouldFireActiveEventOnVisibilityChange(event) && !waitingOnVoiceCommand) trackingManager.onReadyToSendAnalyticsEvent();
      if (waitingOnVoiceCommand) {
        dispatch(setWaitingOnVoiceCommand(false));
      }
      dispatch(resetAppIfStale(location, this.awayFromAppTimestamp));

      if (__OTTPLATFORM__ === 'FIRETV_HYB') {
        systemApi.setDefaultCaptionStyleFromSystemSetting?.();
      }
    }
  };

  renderPageContent() {
    const { children, isContentNotFound, isServiceUnavailable } = this.props;
    const { renderFallbackPage, error, errorInfo } = this.state;

    // if it catches some JS errors anywhere in the child component tree, show a fallback page (500 page) instead of leaving the page blank
    if (renderFallbackPage || isServiceUnavailable) {
      return <FatalErrorMessage error={error} errorInfo={errorInfo} />;
    }
    if (isContentNotFound) {
      return <OTTNotFound />;
    }
    return children;
  }

  renderBackgroundWrapper() {
    const { location, params, isContentNotFound, isServiceUnavailable, enablePreviewVideoBackground, shouldPausePreviewVideo } = this.props;
    const { renderFallbackPage } = this.state;
    const hideBackgroundWrapper = isContentNotFound || isServiceUnavailable || renderFallbackPage;
    return !hideBackgroundWrapper
      ? <OTTBackgroundWrapper
        location={location}
        params={params}
        enablePreviewVideoBackground={enablePreviewVideoBackground}
        shouldPausePreviewVideo={shouldPausePreviewVideo}
      />
      : null;
  }

  setPathnameRedirectFromScreensaver = (url: string) => {
    this.setState({ pathnameRedirectFromScreensaver: url });
  };

  render() {
    const {
      appResetInProgress,
      hotDeeplinkInProgress,
      pathname,
      isRemoteDisabled,
      voiceViewMode,
      showLiveVideoBackground,
      isScreensaverVisible,
      isKidsModeEnabled,
      isSlowDevice,
      isIntroRendering,
      ottFireTVAutocomplete,
    } = this.props;

    if (this.state.shouldShowConsent) {
      return (
        <>
          <ThemeProvider>
            {/* We need to should exit modal when user press back button*/}
            <Suspense fallback={null}>
              <LazyOTTAppModal />
            </Suspense>
          </ThemeProvider>
          <div ref={this.oneTrustRootRef} className={styles.onetrust} style={{ display: isIntroRendering ? 'none' : 'initial' }} />
        </>
      );
    }

    const appCls = classNames(styles.ottApp, {});

    const isTTSFullScreenModeEnabled = this.includeVoiceView && voiceViewMode === TTS_MODE.fullscreen;
    const appContentDimmable = classNames(styles.appContent, {
      [styles.hide]: isScreensaverVisible || isTTSFullScreenModeEnabled,
    });

    const renderSplashScreen = isRemoteDisabled || appResetInProgress;
    const showDevLogsOverlay = !(__PRODUCTION__ && !__IS_ALPHA_ENV__) && !(__SERVER__ && SHOULD_FETCH_DATA_ON_SERVER);

    const ToastContainerComponent = isSlowDevice ? ToastContainer : AnimatedToastContainer;

    const showSpinner = hotDeeplinkInProgress;

    // fb instream script needs an ID to reference, #app will always be available
    const app = (
      <ThemeProvider>
        <div id="app" className={appCls}>
          <ToastProvider>
            {toasts =>
              <ButtonAnimationContext.Provider value={this.enabledButtonAnimation}>
                {this.renderBackgroundWrapper()}
                <Suspense fallback={null}><LazyOTTAppModal /></Suspense>
                {this.includeVoiceView ? <VoiceView pathname={pathname} mode={voiceViewMode} /> : null}
                {isScreensaverVisible
                  ? <OTTScreensaver
                    setPathnameRedirectFromScreensaver={this.setPathnameRedirectFromScreensaver}
                  />
                  : null}
                <LivePlaybackProvider>
                  {!(__SERVER__ && SHOULD_FETCH_DATA_ON_SERVER) && showLiveVideoBackground
                    ? <Suspense fallback={null}><LazyOTTLivePlayer /></Suspense>
                    : null}
                  <div className={appContentDimmable} aria-hidden="true" aria-disabled="true">
                    <TubiSplash isVisible={renderSplashScreen} />
                    {showSpinner ? (<div className={styles.loadingSpinnerWrapper}><Spinner className={styles.loadingSpinner} /></div>) : null}
                    <TalkbackWarning />
                    {!renderSplashScreen && this.renderPageContent()}
                  </div>
                </LivePlaybackProvider>
                {showDevLogsOverlay
                  ? <>
                    <Suspense fallback={null}><LazyExposureLogOverlay /></Suspense>
                    <Suspense fallback={null}><LazyMemoryUsageOverlay /></Suspense>
                  </>
                  : null}
                <MobileToOTTSignInToast />
                <ToastContainerComponent toasts={toasts} theme="light" />
                {__OTTPLATFORM__ === 'TIZEN' ? <EmptyComponentWithSamsungHooks /> : null}
                <PreloadBundle />
              </ButtonAnimationContext.Provider>
            }
          </ToastProvider>
        </div>
      </ThemeProvider>
    );
    const useReactQuery = ottFireTVAutocomplete.getValue() && !isKidsModeEnabled;
    if (useReactQuery) {
      return (
        <ReactQueryProvider>
          {app}
        </ReactQueryProvider>
      );
    }
    return app;
  }
}

export interface FetchDataParams {
  getState: () => StoreState;
  dispatch: TubiThunkDispatch;
}

export interface FetchDataDeferredParams {
  getState: () => StoreState;
  dispatch: TubiThunkDispatch;
  location: Location;
  experimentManager: ReturnType<typeof ExperimentManager>;
}

function fetchDataDeferred({ dispatch, getState, location }: FetchDataDeferredParams) {
  const promises = [];
  // The initial state has already been set during SSR in fetchData and server/render.ts
  const state = getState();

  if (isLoggedInSelector(state)) {
    promises.push(dispatch(loadAuth(location)));
    // When relying purely on CSR, such as on Samsung devices or when failsafe is enabled,
    // the loadUserSettings in fetchData may not execute. In this case, we will attempt to
    // load user settings on the client side. However, if the settings are already loaded,
    // setting the first argument (forced) to false should not have any side effects.
    promises.push(alwaysResolve(dispatch(loadUserSettings(false, state.auth.user?.token))));
    promises.push(alwaysResolve(dispatch(loadQueue(location))));
    promises.push(alwaysResolve(dispatch(loadReminder())));
    promises.push(alwaysResolve(dispatch(loadHistory())));
  }

  return Promise.all(promises)
    .catch((err) => {
      logger.error(err, 'Error while fetching deferred data - App');
      throw err;
    });
}

function fetchData({ getState, dispatch }: FetchDataParams) {
  const state = getState();

  if (isLoggedInSelector(state)) {
    // will try loadUserSettings twice, and will resolve true no matter what (if first attempt fails)
    return tryTwice(loadUserSettings, dispatch, true);
  }
}

export const mapStateToProps = (state: StoreState, ownProps: OwnProps) => {
  const { location } = ownProps;
  const {
    auth,
    fire,
    ui,
  } = state;
  const { pathname, query } = location;
  const { user } = auth;
  const { isKidsModeEnabled, isServiceUnavailable, isRemoteDisabled, notFound, waitingOnVoiceCommand, isSlowDevice } = ui;
  const { appResetInProgress, hotDeeplinkInProgress, appVersion, showModal: isShowingModal, inAppMessage } = fire;
  const shouldPausePreviewVideo = isSidePanelActiveSelector(state) || inAppMessage.isToastVisible;
  const isComingSoon = isComingSoonSelector(state, ownProps);
  const isNewCategoryPageEnabled = ottFireTVNewCategoryPageSelector(state);
  const isInSearchMetadataExperiment = ottShowMetadataOnSearchSelector(state);

  let showLiveVideoBackground = false;
  let enablePreviewVideoBackground = false;
  let banBackButton = pathname === OTT_ROUTES.ageUnavailable;

  const isLivePlaybackPage = isOTTLiveNewsUrl(pathname);

  if (pathname === OTT_ROUTES.home || pathname === OTT_ROUTES.liveMode) {
    const selectedVideo = activeVideoSelector(state, { pathname: location.pathname });
    if (selectedVideo) {
      showLiveVideoBackground = selectedVideo.type === LINEAR_CONTENT_TYPE;
    }
  }

  const currentPage = getPageType(pathname);
  if (isComingSoon) {
    enablePreviewVideoBackground = false;
  } else if (
    pathname === OTT_ROUTES.home
    || pathname === OTT_ROUTES.movieMode
    || pathname === OTT_ROUTES.tvMode
    || pathname === OTT_ROUTES.myStuff
    || pathname === OTT_ROUTES.espanolMode
    || (getPageType(pathname) === BgPageType.SEARCH && /* istanbul ignore next */ isInSearchMetadataExperiment)
    || isDetailsPageUrl(pathname)) {
    enablePreviewVideoBackground = isVideoPreviewEnabledSelector(state);
  } else if (currentPage === BgPageType.CONTAINER_DETAILS || (currentPage === BgPageType.CONTAINER_LIST && isNewCategoryPageEnabled)) {
    enablePreviewVideoBackground = isContainerVideoPreviewEnabledSelector(state);
  }

  const isScreensaverVisible = isScreensaverVisibleSelector(state);

  let voiceViewMode = TTS_MODE.default;
  if (__DEVELOPMENT__ || __STAGING__ || __IS_ALPHA_ENV__) {
    voiceViewMode = Cookie.load(TTS_COOKIE_NAME) || TTS_MODE.default;
  }

  if (isLivePlaybackPage) {
    showLiveVideoBackground = true;
    banBackButton = true;
  }

  if ((isShowingModal && pathname === OTT_ROUTES.home) || inAppMessage.isToastVisible || isKidsModeEnabled) {
    showLiveVideoBackground = false;
  }

  const isCoppaEnabled = isCoppaEnabledSelector(state);
  const isPlaybackDeepLinked = isPlaybackDeeplinkSelector(state);
  const isKidsModeOrIsParentalRatingKids = isKidsModeOrIsParentalRatingOlderKidsOrLess(state);
  const isGDPREnabled = isGDPREnabledSelector(state);

  return {
    appResetInProgress,
    hotDeeplinkInProgress,
    hasExitApi: hasExitApi(),
    isContentNotFound: notFound,
    isPlaybackDeepLinked,
    isKidsModeEnabled,
    isKidsModeOrIsParentalRatingKids,
    isServiceUnavailable,
    isRemoteDisabled,
    pathname,
    isScreensaverVisible,
    query,
    location,
    voiceViewMode,
    showLiveVideoBackground,
    enablePreviewVideoBackground,
    shouldPausePreviewVideo,
    banBackButton,
    waitingOnVoiceCommand,
    isCoppaEnabled,
    isComingSoon,
    isLoggedIn: !!user,
    appVersion,
    isShowingModal,
    isSlowDevice,
    isGDPREnabled,
    isIntroRendering: shouldRenderIntroVideoSelector(state),
  };
};

const connected = hoistNonReactStatics(
  connect(mapStateToProps)(App),
  App,
);

export default withReactRouterModernContextAdapter(
  withPlayerContextProvider(
    withExperiment(
      withYouboraExperimentMapProvider(connected),
      {
        ottAndroidtvIntroVideoFps: OTTAndroidtvIntroVideoFps,
        ottFireTVAutocomplete: OttFireTVAutocomplete,
        ottFireTVRTU: OttFireTVRTU,
      }
    )
  )
);
