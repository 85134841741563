export const COOKIE_SERVED_OTT_LANDING_PAGE = 'served_ott_landing_page_v3';
export const COOKIE_IS_KIDS_MODE_ENABLED = 'is_kids_mode_enabled';
export const COOKIE_IS_ESPANOL_MODE_ENABLED = 'is_espanol_mode_enabled';

// age gate cookies and its values
export const AGE_GATE_COOKIE = 'age_gate_cookie';
export const COPPA_COMPLIANT = 'coppa_pass';
export const NOT_COPPA_COMPLIANT = 'coppa_fail';
export const COPPA_REQUIRE_LOGOUT = 'coppa_require_logout';
export const AGE_GATE_BIRTHDAY = 'age_gate_birthday';

export const HAS_USED_PMR = 'has_used_pmr';

export const QA_PROXY_COOKIE = 'qa_proxy_cookie';

export const HAS_VIEWED_CW_UG_INTEGRATION_NOTIFICATION = 'has_viewed_cw_ug_integration_notification';

export const SHOWN_FAVORITE_TOAST = 'shown_favorite_toast';

export const COOKIE_RELAUNCH_INFO = 'relaunch_info';

export const FIRST_LOAD_PAGE_IS_CATEGORIES_ON_WEB = 'first_load_page_is_categories_on_web';

export const COOKIE_ADVERTISER_ID = 'idfa';

export const COOKIE_ADVERTISER_ID_EXPIRES_MS = (1000 * 60 * 60 * 24 * 365) * 10; // 10 years
export const COOKIE_HAS_VIEWED_CW_CONSENT = 'has_viewed_cw_consent';
export const PERSONALIZATION_PROMPT_FOR_NEW_USERS_WITHIN_7_DAYS = 'new_user_within_7_days';

export const SUBMITTED_PERSONALIZATION = 'submitted_personalization';
export const IS_VALID_USER_FOR_PERSONALIZATION = 'is_valid_user_for_personalization';
export const DISMISSED_PERSONALIZATION_PROMPT = 'dismissed_personalization_prompt';

export const COOKIE_LOGIN_INVOKED = 'login_invoked';

export const DISABLE_ANIMATIONS_COOKIE_NAME = 'disableIntroAnimations';
