import { getSystemApi } from 'client/systemApi/default';
import platformHash from 'common/constants/platforms';
import FeatureSwitchManager from 'common/services/FeatureSwitchManager';
import type { VideoResourceType } from 'common/types/video';
import { VIDEO_RESOURCE_CODEC, VIDEO_RESOURCE_RESOLUTION } from 'common/types/video';
import { isSamsungBefore2017 } from 'common/utils/tizenTools';

const getVideoResourceTypes = ({
  tizenUseHls = false,
}: {
  tizenUseHls?: boolean;
}): VideoResourceType[] | undefined => {
  if (!FeatureSwitchManager.isDefault(['Player', 'VideoResourceType'])) {
    return [FeatureSwitchManager.get(['Player', 'VideoResourceType'])] as VideoResourceType[];
  }
  if (tizenUseHls) {
    // For Samsung 2015 and 2016 we do
    // not have support for common encryption (PSSH)
    if (isSamsungBefore2017()) {
      return ['hlsv6'];
    }
    return ['hlsv6_widevine_psshv0', 'hlsv6'];
  }
  return platformHash[__OTTPLATFORM__ || __WEBPLATFORM__]?.videoResourceTypes;
};

const isHEVCEnabled = (enableHEVC?: boolean): Promise<boolean> => {
  if (typeof enableHEVC !== 'undefined') {
    return Promise.resolve(enableHEVC);
  }
  if (!FeatureSwitchManager.isDefault(['Player', 'HEVC'])) {
    return Promise.resolve(FeatureSwitchManager.isEnabled(['Player', 'HEVC']));
  }
  return getSystemApi().supportHEVC();
};

const getResolution = async (enable4K?: boolean): Promise<VIDEO_RESOURCE_RESOLUTION> => {
  if (!FeatureSwitchManager.isDefault(['Player', 'RESOLUTION'])) {
    return FeatureSwitchManager.get(['Player', 'RESOLUTION']) as VIDEO_RESOURCE_RESOLUTION;
  }
  const specialResolution = getSystemApi().getSpecialResolution();
  if (specialResolution) {
    return specialResolution;
  }
  const is4KSupported = enable4K ?? await getSystemApi().support4K();
  return is4KSupported ? VIDEO_RESOURCE_RESOLUTION.RES_4K : VIDEO_RESOURCE_RESOLUTION.RES_1080P;
};

const getLimitResolutions = async ({
  enableHEVC,
  enable4K,
}: {
  enableHEVC?: boolean,
  enable4K?: boolean,
}) => {
  const codec = await isHEVCEnabled(enableHEVC) ? VIDEO_RESOURCE_CODEC.HEVC : VIDEO_RESOURCE_CODEC.AVC;
  const resolution = await getResolution(enable4K);
  const result = [`${VIDEO_RESOURCE_CODEC.AVC}_${resolution === VIDEO_RESOURCE_RESOLUTION.RES_4K ? VIDEO_RESOURCE_RESOLUTION.RES_1080P : resolution}`];
  if (codec === VIDEO_RESOURCE_CODEC.HEVC) {
    result.push(`${codec}_${resolution}`);
  }
  return result.map(item => item.toLowerCase());
};

export const getVideoResourceQueryParameters = async ({
  enableHEVC,
  enable4K,
  tizenUseHls,
}: {
  enableHEVC?: boolean;
  enable4K?: boolean;
  tizenUseHls?: boolean;
} = {}) => {
  const videoResourceTypes = getVideoResourceTypes({
    tizenUseHls,
  });
  const limitResolutions = await getLimitResolutions({
    enableHEVC,
    enable4K,
  });
  if (videoResourceTypes && videoResourceTypes.length) {
    return {
      limit_resolutions: limitResolutions,
      video_resources: videoResourceTypes,
    };
  }
  return {
    limit_resolutions: limitResolutions,
  };
};
