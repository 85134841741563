import type { Store } from 'redux';

import ExperimentManager, { TubiExperiments } from 'common/experiments/ExperimentManager';
import type StoreState from 'common/types/storeState';

declare module 'common/experiments/ExperimentManager' {
  export namespace TubiExperiments {
    export let ottFireTVNewCategoryPage: 'webott_firetv_new_category_page_v2';
  }
}

TubiExperiments.ottFireTVNewCategoryPage = 'webott_firetv_new_category_page_v2';

export const FIRETV_NEW_CATEGOEY_PAGE = {
  namespace: 'webott_firetv_new_category_page_v2',
  parameter: 'enabled',
};

export const getConfig = () => {
  return {
    ...FIRETV_NEW_CATEGOEY_PAGE,
    id: TubiExperiments.ottFireTVNewCategoryPage,
    experimentName: 'webott_firetv_new_category_page_v2',
    defaultValue: false,
    treatments: [
      { name: 'control', value: false },
      { name: 'enabled', value: true },
    ],
    enabledSelector: () => {
      return __ISOTT__;
    },
    inYoubora: false,
  };
};

export default (store?: Store<StoreState>) => ExperimentManager(store).registerExperiment(getConfig());
