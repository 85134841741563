import { SKINS_AD_FEATURE_SWITCH_KEY } from 'common/features/skinsAd/constants';
import FeatureSwitchManager from 'common/services/FeatureSwitchManager';
import type { Sponsorship } from 'common/types/container';

export function getMockSponsorship(): Sponsorship {
  // Taken from original sponsorship campaign
  return {
    brand_name: 'Alter Ego',
    spon_exp: '50305',
    image_urls: {
      brand_background:
        'https://mcdn.tubitv.com/brand_spotlight/alterego/demo/cached/AE_Brand_Background_1920.png?v=mock',
      brand_color: 'https://mcdn.tubitv.com/brand_spotlight/alterego/demo/cached/AE_Brand_Gradient_1x558.png?v=mock',
      brand_logo: 'https://mcdn.tubitv.com/brand_spotlight/alterego/demo/cached/AE_brand_logo_32.png?v=mock',
      brand_graphic: 'https://mcdn.tubitv.com/brand_spotlight/alterego/demo/cached/AE_Brand_Graphic_108.png?v=mock',
      tile_background: 'https://mcdn.tubitv.com/brand_spotlight/alterego/demo/cached/AE_Brand_Gradient_1x228.png?v=mock',
    },
    pixels: {
      homescreen: [
        'https://pixel.staging-public.tubi.io/v2/display/homescreen/3oQb0RHTceC_LfR4eiHR/xMGCKs9z7Bvo1IZrriSYi-NCxdJ1YLr8ZIWdRAGFGE-XLWunwkzQyaAqZe35Ca4HhMpe8lHc6OSSjnIzp6xX9S--5f8ggYo864pXXgOA9j_-r9ngSTtZMb_TCncqdLZEv-tM4tmef3by3qWO4sfrOU95LRwuR_m_mwpvDSWWlAxFw2_3B47jzvnZm1tt3y2I9_XFLmzZM_mqhCDqEvj0Z2v4sRrA4aoJZLQ804ORPRul1uviK9_RAArOs1g=?cb=(ADRISE:CB)',
      ],
      container_list: [
        'https://pixel.staging-public.tubi.io/v2/display/container_list/3oQb0RHTceC_LfR4eiHR/gEkdn6OMCc3sbEgAGh37lyQjChmXM4YkLvFofMWYUEHKn22UWadmHMwUpKA_jTPs2cHmZH9g47-DYVQLdoO0VkufqQgi4j_fhkVwyeUQCzywLrqE3H8KDZjjH1FrJxMKodpQAyq2YWRPadQv_za5qFTLGS0xpuabGQvgKXtKMSVGTuKcn-0hWt1CxkU3BHY4OQ4mDX4xwidz8P0nkf_avPhr4oE0me3bbs5pf_ZarjK0ymJdxx4Z39BDyDUcR6mf?cb=(ADRISE:CB)',
      ],
      container_details: [
        'https://pixel.staging-public.tubi.io/v2/display/container_details/3oQb0RHTceC_LfR4eiHR/AtRziWH7AgY6HodFOeRgrAYSWa02rpSCfrjkalpeuQDAS9Ew5xCErk7vinTW087_dZFPlRFxCdypIsZCUr1lXjzlCCWeHL1vwZBcpJFQTpgOb0g59luy5F4EKltiSALl_TEjX1apxanI2xymNpCWUAXxVR3erW1UYozhxzk0-VbYeKZt4pQk_O1CksWQbwYlko5_vrefWOM4rg9723-MDYp1Ileq5jl7IOsErXQUEZYhcVBQqj7Os5CSNx_uGWM1AEI=?cb=(ADRISE:CB)',
      ],
    },
  };
}

export const getMockSkinsAd = (type?: string) => {
  switch (type) {
    case 'moana':
    default:
      return [
        {
          id: '52310',
          creatives: [
            {
              type: 'video',
              id: '51372',
              ad_id: '51372',
              imptracking: [
                'https://ad.doubleclick.net/ddm/trackimp/N186801.4459160TUBI-ORION/B32629483.406687955;dc_trk_aid=598373882;dc_trk_cid=224035339;ord=8837403753844000704;dc_lat=;dc_rdid=6f23c566-1be5-5ff6-b45f-c9500cb7b064;us_privacy=1YN-;tag_for_child_directed_treatment=;tfua=;gdpr=0;gdpr_consent=;ltd=;dc_tdv=1?',
                'https://secure-gl.imrworldwide.com/cgi-bin/m?ca=nlsn647352&cr=crtve&ce=tubitv&pc=tubitv_plc0001&ci=nlsnci1240&am=3&at=view&rt=banner&st=image&cy=2&ep=&c42=provapn,tubitv&pub=tubitv&c10=plt,OTT&c45=provgencls,movie&pubid=6f23c566-1be5-5ff6-b45f-c9500cb7b064&c8=devgrp,AMN&c12=apv,3201013c553d2d2d3764475d3e7cafda&c9=devid,6f23c566-1be5-5ff6-b45f-c9500cb7b064&c13=asid,P66B0328C-351B-46BD-A27E-A492D78C51D4&c43=provcg,The_Curse_of_the_Necklace&c44=provgen,GENRE_HORROR&r=1302597275498922769&uoo=0&c36=cref1,MV022900190000',
                'https://secure-gl.imrworldwide.com/cgi-bin/m?ca=nlsn314054&cr=crtve&ce=tubitv&pc=tubitv_plc0001&ci=nlsnci734&am=3&at=view&rt=banner&st=image&cy=2&ep=&c42=provapn,tubitv&pub=tubitv&c10=plt,OTT&c45=provgencls,movie&pubid=6f23c566-1be5-5ff6-b45f-c9500cb7b064&c8=devgrp,AMN&c12=apv,3201013c553d2d2d3764475d3e7cafda&c9=devid,6f23c566-1be5-5ff6-b45f-c9500cb7b064&c13=asid,P66B0328C-351B-46BD-A27E-A492D78C51D4&c43=provcg,The_Curse_of_the_Necklace&c44=provgen,GENRE_HORROR&r=3244188162774026244&uoo=0&c36=cref1,MV022900190000',
                'https://ads4.production-public.tubi.io/pixel/v3/vod/impression/AMAZON?pos=1&id=yEr4DzaKIuWXmbOzfBjQ&data=bYHPOQ4Y97Yb3y1RGXYAIvFe8Bs5O-_pwuTGRLGRP0umyn413EmzruWQ-4jlxjtgO9deG_cnX9FLPIJ4XAAjXn9ErRwPWLLMkhOUzZ2985YYma1zxWj3I4ZyjuSNQ70dRjvdlCaSn0fXTf1MAqO0vfw5-vCAlRAJpJ0HLNjPf6IBlevgdTZwmOhHnjnOIzQnBQ-Q1PmUk1GIgLUZ7qCrkhD7WNixwC8o4gUg56sSiYFY3SXYzvP-okjqgQuualvc_TooQIi44ODHRLCS2EMSTq6-8Jb-SUR1sw3zt0NUW_pWm2pDObfzeeTQMGYK-P5EwoYgxNI0DE5D9svIlEE5BmG5bO3I5qTPvQfue-aamZ0ziJlyMHNCFxLW819I9yRERQJW9cUgwzNeOtxckxPzGnP2',
              ],
              error:
                'https://ads4.production-public.tubi.io/pixel/v3/vod/error/AMAZON?err_code=%5BERRORCODE%5D&pos=1&id=yEr4DzaKIuWXmbOzfBjQ&data=1an6bIOzGI5WEnzSkgD9VjwwxYMkRtUM5h54Vh_I2hd-U_tOPdNuNGhXU-85YLPgdxRyxzj1AGpQKLeWwwXOXOelQU2B5hrdPhEtr_tUPkl_lYcmjX5vtqc8ZrOcQKxspZ5LoQto_pcmDGUTyOvhUl2QaJcnfPEQiAYRWTuZ_mLnEQgUdHVC02pVhbGW36A9A63bP_yh9C9tre6HkQOuFVFQbwFsL6yJT181GTVtxCiTgxjBz_YiDE4oKRWq3YtbvZAbKAt8zTpUX6-LZaCqjWowEuoztQD1asbkF3vZOwld-zdPfwTuu_M0kQcM5sXuHl29-IAoyFQBRwWZnv3MPRm0iOojavAleE2ejeSSO5IiMRMMSqBGpOHMhI76E0RjW9DoqfVBPEfvadoa02rckTY1',
              media: {
                duration: 28.472000128,
                streamurl:
                  'https://ark.tubi-staging.video/v2/dac5ef74fa4e5ac433d30dd526e8901d/5e3a08a8-66f8-450e-98e3-9afcd409ea11/854x428_1200k.mp4',
                trackingevents: {
                  tracking_0: [
                    'https://ads4.production-public.tubi.io/pixel/v3/vod/progress0/AMAZON?pos=1&id=yEr4DzaKIuWXmbOzfBjQ&data=IXXvKeEHX0FwEQiP0s2pAOnE_qiN1VkC3Lq-LYPzltnRo2uCii6scJXWb59POf1aI0GqPWXeIHd-RzuEA7_Ll25jr3AR8jdXmnDn76mI_3V2F1btpV3kFYWdsvC6CJxx5g_eLZOUwHguZzoJ2puBp3MYRVdS3CLN5O-ru2NBdJGjX948-RCD4BfjH8SQn9YvU3yBDoUoSzB_DKW7DC_3kI7n-gCQq1S1Bo5JC_CE7ZU2O_ZNt2NBhCPd4poufj9VAi1satO7BsBrs_CZwK6YM3BWjS89VvavKeZZGcHydDmuf-xfwc2OkMiyaNTSj8xxlv7WmmfaISUpvKbdhYCVPaxRQE3oZPKDzTTqLhI_E2o',
                  ],
                  tracking_25: [
                    'https://ads4.production-public.tubi.io/pixel/v3/vod/progress25/AMAZON?pos=1&id=yEr4DzaKIuWXmbOzfBjQ&data=QBLYoR7nbdZ6669PWhmeCykwLO9i9_zYXvpeTZcgQ01nP6WB12sPbPxK8IDknx4creiimfETkPjrZ5WN6Ut96pWqD5pCLnMIIY0rbLwLsk9pPaj-QLcIyrF2SLvqJ3ZKQnVlNNlJtGbcbve3nVs71MbWvPQqo9otBXd6cd82CIc4xCNTWW3-5g6-hzQO0EfUq3oydqLAU8kn3Kg7P7vyf5jWHxYMtDsUAynkrjoQ9I2G9NdY9IiBvPPDIlwKb5ruTNb-0l3vY3weTWuys_2015yd-U9mox6G9EeebW2lIAX5pMAvvt_cEVVDuCf6LrZCLoVAuTVIP5b8QQ2rghBBFn_MkjVYtK2SyH--Cj8_shU',
                  ],
                  tracking_50: [
                    'https://ads4.production-public.tubi.io/pixel/v3/vod/progress50/AMAZON?pos=1&id=yEr4DzaKIuWXmbOzfBjQ&data=rYxELSvaHerYT7oMB90x4l6pzGUW8Phi5EF8yD2sTLiD2ktqzFuf8E-l4PEUjio4lrWe0ygB0ZfFHDLrDf-n4gpXKNdFywpjARIAr9tqYfBYBxUi2sZdQ1NI-nJEgWNExeJ6Ztk513d9LW5iRjw6_mJZrTmnFAZF0aYXNuRDguC0d4Oel3N7wqIMcfaWedSy3V3DbQ_p2dP6-n8T5pLqQ_n_bw9pUy3wfEjU8Wa-7Loi0OXezfOw1RGDqxyaFEyvwscLbdVFEECI5rNYRe_-YLE-b6Sq8Jxl-TsdjKaw1gdXthvOvM9D7U1rYw85IZcfW4zkpHXMzEZIlZ6v_Vjal4CPlBicmn4kaPwSA1mHjco',
                  ],
                  tracking_75: [
                    'https://ads4.production-public.tubi.io/pixel/v3/vod/progress75/AMAZON?pos=1&id=yEr4DzaKIuWXmbOzfBjQ&data=DvOvUnlhtCLjKfaGdgu2Qgyv6J_quyFskH6jWVIW6H1bN7u_L4S7CsmZbmwKQbYs_MJbshkhaZOsEJMUL5lzBJi-dQ9tIZ18sru0ePMIDJvVfRx93Y9StnIB2D37CvF7Un2FZzHFw8F8b-wkdPctTnBbAX2cONmacbW06bA_ANd_-NyxjGMGTid0a0ZBmyg1X9wTZQFgFzwulpaFGsAKEdMEp4C2xFirLdRli_jYbi0YyIr87WkU9WRLU8RQpv4N3qp5vhu3P9wam1aT-GmyLXi9Lhgi_JrCrzbHeVZo2ojZ8YqRd8XAKVztq2RyXM2X9bIXVccftkzfRAsmOy3gHDfps8cQnnGkEnME8m-YaJo',
                  ],
                  tracking_100: [
                    'https://ads4.production-public.tubi.io/pixel/v3/vod/progress100/AMAZON?pos=1&id=yEr4DzaKIuWXmbOzfBjQ&data=C4mmE8zFgjJvM2GhpkH9HEKnX-dS9mhizoL1nzzAUnkgKI7PyXWj1hrJvJZ6hXtT_wblB_3D9B5xp5CXGaivAFWVNzPBPbT86Zum6c6lRfCwUQdLNro0iD9Mkx38-VCZWIzJYBXLlZ_aINBDmGLYgb9kIT1H0CPXuwJBlU2B43-Yvawc6P3WBzQA33mhjg06qYmub3Z0zls2kmwwQ9bX4-eGbS4TVi0OIL_lQhm6LW7spCwG-e7Ce-ALNWeJLuTXhLijQHRFQ6RQEPlLPa3irXouY_FsOxAqMlSdo4tIp2CUWQKbO87Q43W0c67VSbzrSGCapgIy7-klvsj4-sHJNmmKqMnZuNqBKRzGZnPlbFc',
                  ],
                },
              },
            },
            {
              type: 'native_custom_video',
              id: '51371',
              image_imptracking: [
                'https://ads.production-public.tubi.io/proxy/v2/coppa/spotlight/impression/AMAZON?data=da2IgxwIiL69STjY6CjCOnfN2styDcovELeibJzAZG7Mu9derx_0vvb68-wL1themvfpe05ccp_r4wANARCCjLACoInHLe0ceHS4Xkf69rzSksbtADQA-uXO7jm0kB8nV0HjI-vYp3G7iKL-KgiAEi0F4Xtw3Cgxl_EYiJVXrMBSsWy4S0CQKY2DdGULDdDVF1gPBLVVMkU9b26HyFHoIpuxe9_s2c2S_xH_Q8at6E8XHmee1uLfiCDOpP7muZQ',
              ],
              click_trackings: [
                'https://ads.production-public.tubi.io/proxy/v2/coppa/spotlight/click/AMAZON?data=Tlxl5Nw4xz3BzjngwaEgapCWx8zPxeHoOaJpwGIMSmO9eH-cAZVcJj_JupmwwxGxRWCJW68SdMAR2-b-kyebVfSSqAgg3lOKm58v1rDgp8gYeQDd_lEBMWSdwaFwvP_YO_O1eoYcdgWxSUAXGvJ6WXoOEi9Vv8NW1RfxAcTf8mWIZrTfFGy1Ttdv9rCM70P4udG6_mF_Vqd55nLaQwT864CjEAtnIxkjuKZA1ZnDp20QwMqglIsIC622Huz6mCM',
              ],
              media: {
                duration: 30.0,
                streamurl: 'https://ark.tubi-staging.video/v2/69223ac41f853631341bb0b503e12f3b/9e114423-f63c-4760-8e91-0ff1db031968/1280x720_1500k.mp4',
              },
              image: 'https://mcdn.tubitv.com/tubitv-assets/img/skinsAd/MoanaBg.png', // background image, image cdn url, img accompanying the video, uploaded by client
              color: '#08549A', // the brand color when image not in focus, optional
              custom: {
                tile_img: 'https://mcdn.tubitv.com/tubitv-assets/img/skinsAd/MoanaLandscape.png', // image cdn url, tile image, uploaded by client, optional
                footer_img: 'https://mcdn.tubitv.com/tubitv-assets/img/skinsAd/MoanaFooter.png', // image cdn url, uploaded by client, optional
              },
              offer: {
                logo: 'https://mcdn.tubitv.com/tubitv-assets/img/skinsAd/MoanaLogo.png', // image cdn url with h=${HEIGHT} macro
                title: 'Presented by', // title of the offer (max 25? chars)
                body: 'Now Playing Only in Theaters',
                cta: '', // call to action text (optional, max 25? chars) ie Scan the code..., optional
              },
            },
          ],
        },
      ];
  }
};

export const insertSkinsAdToHomeScreen = (body: any) => {
  if (__PRODUCTION__ && !__IS_ALPHA_ENV__) return;
  if (FeatureSwitchManager.isDefault(SKINS_AD_FEATURE_SWITCH_KEY)) return;

  const mockSkinsAdData = FeatureSwitchManager.get(SKINS_AD_FEATURE_SWITCH_KEY) as string;
  const mockedSkinsAd = getMockSkinsAd(mockSkinsAdData);
  body.ads = mockedSkinsAd;
};
