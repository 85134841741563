import type { Store } from 'redux';

import ExperimentManager, { TubiExperiments } from 'common/experiments/ExperimentManager';
import type StoreState from 'common/types/storeState';

declare module 'common/experiments/ExperimentManager' {
  export namespace TubiExperiments {
    export let ottFireTVSkipAdWithHealthscore: 'webott_firetv_skip_ad_with_healthscore_v3';
  }
}

TubiExperiments.ottFireTVSkipAdWithHealthscore = 'webott_firetv_skip_ad_with_healthscore_v3';

export enum FIRETV_SKIP_AD_WITH_HEALTHSCORE_VALUE {
  CONTROL = 'skip',
  RETRY = 'retry',
  SHORT_SEEK = 'short_seek',
}

export const FIRETV_SKIP_AD_WITH_HEALTHSCORE = {
  namespace: 'webott_firetv_skip_ad_with_healthscore_v3',
  parameter: 'operation',
};

export const getConfig = () => {
  return {
    ...FIRETV_SKIP_AD_WITH_HEALTHSCORE,
    id: TubiExperiments.ottFireTVSkipAdWithHealthscore,
    experimentName: 'webott_firetv_skip_ad_with_healthscore_v3',
    defaultValue: FIRETV_SKIP_AD_WITH_HEALTHSCORE_VALUE.CONTROL,
    treatments: [
      { name: 'control', value: FIRETV_SKIP_AD_WITH_HEALTHSCORE_VALUE.CONTROL } as const,
      { name: 'retry', value: FIRETV_SKIP_AD_WITH_HEALTHSCORE_VALUE.RETRY } as const,
      { name: 'short_seek', value: FIRETV_SKIP_AD_WITH_HEALTHSCORE_VALUE.SHORT_SEEK } as const,
    ],
    enabledSelector: () => __OTTPLATFORM__ === 'FIRETV_HYB',
    inYoubora: true,
  };
};

export default (store?: Store<StoreState>) => ExperimentManager(store).registerExperiment(getConfig());
