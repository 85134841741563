/**
 * Fire an array of tracking pixels
 */
export function fireTrackingPixels(urls: string[]) {
  urls.forEach(url => fireTrackingPixel(url));
}

/**
 * Fire a tracking pixel using an image element to bypass CORS restrictions.
 */
export function fireTrackingPixel(url: string): void {
  // these cache-busting macros are reportedly present on some pixels
  const replacedUrl = url.replace('(ADRISE:CB)', `${Date.now()}`);
  const img = new Image();
  img.src = replacedUrl;
  // we intentionally do not handle load or error events; this is a
  // fire-and-forget operation
}
