import type { Store } from 'redux';

import ExperimentManager, { TubiExperiments } from 'common/experiments/ExperimentManager';
import type StoreState from 'common/types/storeState';

declare module 'common/experiments/ExperimentManager' {
  export namespace TubiExperiments {
    export let ottVizioPreInitExtension: 'webott_vizio_pre_init_extension_v4';
  }
}

TubiExperiments.ottVizioPreInitExtension = 'webott_vizio_pre_init_extension_v4';

export enum VIZIO_PRE_INIT_EXTENSION_VALUE {
  CONTROL = 0,
  ENABLE_PRE_INIT_RAINMAKER = 1,
  ENABLE_PRE_INIT_ADS_BUFFER_ENOUGH = 2,
}

export const VIZIO_PRE_INIT_EXTENSION = {
  namespace: 'webott_vizio_pre_init_extension_v4',
  parameter: 'enable_pre_init_mode',
};

export const getConfig = () => {
  return {
    ...VIZIO_PRE_INIT_EXTENSION,
    id: TubiExperiments.ottVizioPreInitExtension,
    experimentName: 'webott_vizio_pre_init_extension_v4',
    defaultValue: VIZIO_PRE_INIT_EXTENSION_VALUE.CONTROL,
    treatments: [
      { name: 'control', value: VIZIO_PRE_INIT_EXTENSION_VALUE.CONTROL } as const,
      { name: 'enable_pre_init_rainmaker', value: VIZIO_PRE_INIT_EXTENSION_VALUE.ENABLE_PRE_INIT_RAINMAKER } as const,
      {
        name: 'enable_pre_init_ads_buffer_enough',
        value: VIZIO_PRE_INIT_EXTENSION_VALUE.ENABLE_PRE_INIT_ADS_BUFFER_ENOUGH,
      } as const,
    ],
    enabledSelector: () => __OTTPLATFORM__ === 'VIZIO',
    inYoubora: true,
  };
};

export default (store?: Store<StoreState>) => ExperimentManager(store).registerExperiment(getConfig());
