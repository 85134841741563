import { createSelector } from 'reselect';

import { getCookie } from 'client/utils/localDataStorage';
import { PERSONALIZATION_PROMPT_FOR_NEW_USERS_WITHIN_7_DAYS } from 'common/constants/cookies';
import { ParentalRating } from 'common/constants/ratings';
import { isLoggedInSelector } from 'common/features/authentication/selectors/auth';
import { ottFireTVTitleOnboardingPersonalizationSelector } from 'common/selectors/experiments/ottFireTVTitleOnboardingPersonalization';
import type StoreState from 'common/types/storeState';
import { isParentalRatingTeensOrLess } from 'common/utils/ratings';

export const isUserQualifiedForPersonalizationExperiment = createSelector(
  () => !!getCookie(PERSONALIZATION_PROMPT_FOR_NEW_USERS_WITHIN_7_DAYS),
  isLoggedInSelector,
  ({ ui: { isKidsModeEnabled }, userSettings: { parentalRating } } : StoreState) => isKidsModeEnabled || isParentalRatingTeensOrLess(parentalRating),
  (newUserWithIn7Days, isLoggedIn, isKidsModeOrIsParentalRatingTeensOrLess) => {
    return isLoggedIn && newUserWithIn7Days && !isKidsModeOrIsParentalRatingTeensOrLess;
  }
);

export const shouldShowTitlePersonalizationPrompt = createSelector(
  (state: StoreState) => ottFireTVTitleOnboardingPersonalizationSelector(state),
  (state: StoreState) => isUserQualifiedForPersonalizationExperiment(state),
  (experimentValue, isUserQualifiedForPersonalizationExperiment) => {
    return experimentValue && isUserQualifiedForPersonalizationExperiment;
  }
);

export const isParentalRatingAdultSelector = createSelector(
  ({ userSettings: { parentalRating } }: StoreState) => parentalRating,
  (parentalRating) => parentalRating === ParentalRating.ADULTS
);
