import { clamp } from '@adrise/utils/lib/tools';
import { Button } from '@tubitv/ott-ui';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { lazySetA11y } from 'common/actions/a11y';
import type { ArrowDirection } from 'common/types/directions';
import type { TubiThunkDispatch } from 'common/types/reduxThunk';
import { findIndex } from 'common/utils/collection';
import { addEventListener, removeEventListener, prependEventListener } from 'common/utils/dom';
import { getOTTRemote, isOTTKeys } from 'common/utils/keymap';
import type { Selection } from 'common/utils/voiceViewPlaybooks';
import type { TubiIntlShape } from 'i18n/intl';

import styles from './OTTSelectableList.scss';

const REMOTE = getOTTRemote();

// fixed selector will remain at the top of the list
const checkIfShouldBeFixed = (fixed: boolean | undefined, items: SelectableItem[], displayCount: number): boolean =>
  typeof fixed === 'undefined' ? items.length > displayCount : fixed;

export interface SelectableItem {
  id: string;
  title?: string;
  a11yText?: string;
  icon?: ReactNode;
  activeIcon?: ReactNode;
  style?: Record<string, unknown>;
  childElement?: ReactNode;
  onClick?: (e?: React.MouseEvent) => void;
  onMouseEnter?: () => void;
}

type WithA11y = undefined | boolean;

export interface OTTSelectableListProps {
  items: SelectableItem[];
  activeId?: string;
  displayCount: number;
  enableCycle?: boolean;
  enableCheckIcon?: boolean;
  fixed?: boolean;
  fixedIdx?: number; // start from 0
  isActive?: boolean;
  listClass?: string;
  itemClass?: string;
  marqueeOnOverflow?: boolean;
  onLeave?: (direction: ArrowDirection) => void;
  onSelect?: (activeIndex: number) => void;
  onChange?: (index: number, loadMoreContainers?: boolean) => void;
  selectedIdx?: number;
  dispatch: TubiThunkDispatch;
  withA11y?: WithA11y;
  // string to announce menu in onceBefore of a11y
  a11yPreface?: string;
  intl: TubiIntlShape;
  enablePropagation?: boolean;
  usePrependEventListener?: boolean;
}

interface OTTSelectableListState {
  lowerIdx: number;
  lastActiveId?: string;
  lastIsActive?: boolean;
}

/**
 * A list of strings that can be navigated up/down using arrow keys (which corresponds to D-PAD entries)
 * Meant for OTT devices
 *
 * items - array of {id, title} to display
 * activeId - id that matches items[x].id will be 'selected'
 * displayCount - number of items to display at once
 * isActive - user is focused on this pane
 * selectedIdx - showing which is selected (not hovered) for non side menus
 */
class OTTSelectableList extends PureComponent<OTTSelectableListProps, OTTSelectableListState> {

  static defaultProps = {
    withA11y: false,
  };

  static getDerivedStateFromProps(nextProps: OTTSelectableListProps, prevState: OTTSelectableListState) {
    // make sure lowerIdx is <= index of activeID * displayCount
    const { lastActiveId, lastIsActive, lowerIdx: currentLowIdx } = prevState;
    const { activeId, isActive, items, displayCount, fixed, fixedIdx, enableCycle } = nextProps;

    if (lastActiveId === activeId && lastIsActive === isActive) return null;

    const newActiveIdx = findIndex(items, item => item.id === activeId);
    if (!enableCycle && fixedIdx) {
      let newLowerIdx;

      if (newActiveIdx <= fixedIdx) {
        newLowerIdx = 0;
      } else if (items.length - newActiveIdx > displayCount - (fixedIdx + 1)) {
        newLowerIdx = newActiveIdx - fixedIdx;
      } else {
        newLowerIdx = currentLowIdx;
      }
      return {
        lowerIdx: newLowerIdx,
        lastActiveId: activeId,
        lastIsActive: isActive,
      };
    }
    if (checkIfShouldBeFixed(fixed, items, displayCount) || currentLowIdx > newActiveIdx) {
      return {
        lowerIdx: Math.max(newActiveIdx, 0),
        lastActiveId: activeId,
        lastIsActive: isActive,
      };
    } if (newActiveIdx - displayCount >= currentLowIdx) {
      return {
        lowerIdx: newActiveIdx - (displayCount - 1),
        lastActiveId: activeId,
        lastIsActive: isActive,
      };
    }
    return null;
  }

  constructor(props: OTTSelectableListProps) {
    super(props);

    // constructor sets state.lowerIdx = 0, make sure activeId prop is within range
    // if not within range, set lowerIdx to indexOf(activeId)
    const { activeId, enableCycle, items, displayCount, fixed, fixedIdx } = props;
    let lowerIdx = 0;
    const activeIdx = findIndex(items, cat => cat.id === activeId);
    if (!enableCycle && fixedIdx) {
      if (activeIdx <= fixedIdx) {
        lowerIdx = 0;
      } else if (items.length - activeIdx > displayCount - (fixedIdx + 1)) {
        lowerIdx = activeIdx - fixedIdx;
      } else {
        lowerIdx = items.length - displayCount;
      }
    } else if (checkIfShouldBeFixed(fixed, items, displayCount)) {
      lowerIdx = Math.max(activeIdx, 0);
    } else if (activeIdx >= 0 && activeIdx <= lowerIdx || activeIdx >= lowerIdx + displayCount) {
      // selectedIdx is out of range of lowerIdx and lowerIdx + displayCount
      lowerIdx = clamp(items.length - displayCount + 1, 0, activeIdx);
    }

    this.state = {
      lowerIdx,
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    const { isActive, usePrependEventListener } = this.props;
    const addListenerFn = usePrependEventListener ? prependEventListener : addEventListener;
    if (isActive) {
      addListenerFn(window, 'keydown', this.handleKeyPress);
    }
  }

  componentDidUpdate(prevProps: Readonly<OTTSelectableListProps>) {
    const { activeId, isActive, items, withA11y, usePrependEventListener } = this.props;
    const addListenerFn = usePrependEventListener ? prependEventListener : addEventListener;

    if (activeId !== prevProps.activeId || isActive !== prevProps.isActive) {
      if (isActive !== prevProps.isActive) {
        if (isActive) {
          addListenerFn(window, 'keydown', this.handleKeyPress);
        } else {
          removeEventListener(window, 'keydown', this.handleKeyPress);
        }
      }
    }

    if (!withA11y) return;
    const activeIdx = findIndex(items, item => item.id === activeId);
    const activeItemChanged = isActive && prevProps.activeId !== activeId;
    const listHasBecomeActive = !prevProps.isActive && isActive;
    if (activeItemChanged || listHasBecomeActive) this.handleUpdateA11y(activeIdx);
  }

  componentWillUnmount() {
    removeEventListener(window, 'keydown', this.handleKeyPress);
  }

  handleUpdateA11y = (activeIdx = 0) => {
    const { items, dispatch, a11yPreface, intl: { formatMessage } } = this.props;
    if (activeIdx < 0) return;

    dispatch(lazySetA11y(async () => {
      const { listPlaybook } = await import(/* webpackChunkName: "voiceViewPlaybooks" */ 'common/utils/voiceViewPlaybooks');
      const itemsForA11y: Selection[] = items.map(item => ({
        ...item,
        title: item.a11yText || item.title,
        hasSubOptions: false,
      }));
      return listPlaybook(itemsForA11y, activeIdx, a11yPreface, formatMessage);
    }));
  };

  onOutOfArea = (direction: ArrowDirection) => {
    if (this.props.onLeave) {
      this.props.onLeave(direction);
    }
  };

  /**
   * handle enter, up, down, and right
   * @param e
   */
  handleKeyPress = (e: KeyboardEvent) => {
    const { keyCode } = e;
    if (!this.props.isActive) return;
    if (!isOTTKeys(keyCode)) return;
    const { items, activeId, enableCycle, onChange, onSelect } = this.props;
    const activeIdx = findIndex(items, item => item.id === activeId);
    // only stop propagation if actionTaken remains true (i.e. a valid key is pressed in switch statement).
    // otherwise, Parent container action (global search in OTTHome) cannot happen.
    let actionTaken = true;
    switch (keyCode) {
      case REMOTE.arrowUp:
        if (onChange) {
          const nextIdx = activeIdx - 1;
          if (enableCycle) {
            onChange(nextIdx < 0 ? items.length - 1 : nextIdx);
          } else {
            onChange(Math.max(nextIdx, 0));
            if (nextIdx < 0) {
              this.onOutOfArea('ARROW_UP');
            }
          }
        }
        break;
      case REMOTE.arrowDown:
        if (onChange) {
          const loadMoreContainers = true;
          const nextIdx = activeIdx + 1;
          if (enableCycle) {
            onChange(nextIdx > items.length - 1 ? 0 : nextIdx, loadMoreContainers);
          } else {
            onChange(Math.min(items.length - 1, nextIdx), loadMoreContainers);
          }
        }
        break;
      case REMOTE.arrowLeft:
        this.onOutOfArea('ARROW_LEFT');
        break;
      case REMOTE.arrowRight:
        this.onOutOfArea('ARROW_RIGHT');
        break;
      case REMOTE.enter:
        if (onSelect) {
          onSelect(activeIdx);
        }
        break;
      default:
        actionTaken = false;
        break;
    }

    if (this.props.usePrependEventListener) {
      if (!this.props.enablePropagation || actionTaken) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    } else if (actionTaken && !this.props.enablePropagation) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  };

  render() {
    const { items, activeId, displayCount, enableCheckIcon, isActive, listClass, selectedIdx, marqueeOnOverflow, itemClass: itemClassFromProp } = this.props;
    const { lowerIdx } = this.state;

    return (
      <div className={classNames(styles.selectableList, listClass)} data-test-id="ottSelectableList">
        {
          items.map((buttonProps, idx) => {
            const { id, title, icon, activeIcon, style, onClick, onMouseEnter, childElement } = buttonProps;
            const idxInRange = idx >= lowerIdx && idx < displayCount + lowerIdx;
            if (!idxInRange) return null;

            const hover = id === activeId && isActive;
            const itemClass = classNames(styles.item, itemClassFromProp, {
              [styles.selected]: !hover && idx === selectedIdx, // if it's hover + selected should show orange bg
              [styles.checked]: enableCheckIcon && idx === selectedIdx,
            });

            const displayIcon = id === activeId && activeIcon ? activeIcon : icon;
            const selected = idx === selectedIdx;
            return (
              <Button
                {...buttonProps}
                key={id}
                hover={hover}
                selected={selected}
                cls={itemClass}
                icon={displayIcon}
                style={style}
                data-test-id="fireButton"
                onClick={onClick}
                onMouseEnter={onMouseEnter}
              >
                {childElement || (
                  marqueeOnOverflow && selected && title
                    ? <MarqueeContent content={title} />
                    : <div className={styles.title}>{title}</div>
                )}
              </Button>
            );
          })
        }
      </div>
    );
  }
}

type MarqueeContentProps = {
  content: string;
};

const MarqueeContent = ({ content }: MarqueeContentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    // Check if the content overflows
    const element = ref.current;
    /* istanbul ignore else */
    if (element) {
      setIsOverflowing(element.scrollWidth > element.clientWidth);
    }
  }, [content]);

  return (
    <div
      className={classNames(styles.titleWithMarquee, {
        [styles.animated]: isOverflowing,
      })}
      ref={ref}
    >
      <span>{content}</span>
    </div>
  );
};

export const RawComponent = OTTSelectableList;

export default connect()(injectIntl(OTTSelectableList)) as React.FC<Omit<OTTSelectableListProps, 'dispatch' | 'intl'> & { dispatch?: OTTSelectableListProps['dispatch'] }>;
